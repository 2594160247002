import {Body2, Subtitle1, Subtitle2, tokens, Spinner} from '@fluentui/react-components';
import {Dropdown, IDropdownOption} from '@fluentui/react';
import {useTranslation} from 'react-i18next';
import {makeResetStyles} from '@griffel/react';
import {DateTime} from 'luxon';

import React from 'react';
import {CommonButton} from '../../../components/button/CommonButton';
import {TeakEntityCard} from '../../../components/cards/TeakEntityCard';
import {FormattedDateTime} from '../../../components/dateTime/FormattedDateTime';
import {TEAK_ENTITY_STATUS} from '../../../constants/teakEntityStatus';
import {teakTypeNamesMap} from '../../../constants/teakTypeNamesMap';
import {DashboardContext} from '../../app/contexts/DashboardContext';

const useDropdownStyles = makeResetStyles({
	'& div': {
		width: '100%', //same max width as the max widht of the modal content
	},
	'& div::after': {
		borderRadius: '12px',
	},
	'& span': {
		border: 'none',
		textOverflow: 'ellipsis',
		color: tokens.colorBrandBackground,
		fontWeight: 'bold',
		'& i': {
			fontSize: '15px',
			fontWeight: '700',
		},
	},
});
interface TeakEntityListProps {
	data?: any;
	goBack: () => void;
	confirmEntitySelection: (entity: any) => void;
	loadingResults?: boolean;
	searchResults: (teakType: string, startTime: DateTime, endTime: DateTime, area: string, teakFeatures: Array<string>, meetingName: string) => void;
}

export const TeakEntityList: React.FunctionComponent<TeakEntityListProps> = (props: TeakEntityListProps) => {
	const {t} = useTranslation();
	const {goBack, data, confirmEntitySelection, searchResults, loadingResults} = props;
	const [selectedEntity, setSelectedEntity] = React.useState();
	const {selectedOffice} = React.useContext(DashboardContext);
	const teakTypeName: string | undefined = teakTypeNamesMap.get(data?.teakType?.__t);
	const teakTypeNameTranslated: string = teakTypeName ? t(teakTypeName) : t('teakTypes.generic');
	const dropdownStyles = useDropdownStyles();
	const area = data?.area?._id;

	function isEntityAvailable(entity: any) {
		// if status is not available, entity is not available
		if (entity.status !== TEAK_ENTITY_STATUS.available) {
			return false;
		}

		// if the user didnt select any features, show all entities
		if (!data.teakFeatures || data?.teakFeatures.length === 0) {
			return true;
		}

		// if user selected features but entity doesnt have any features, do not show it in the list
		if (!entity?.teakFeatures || entity.teakFeatures.length === 0) {
			return false;
		}
		return entity?.teakFeatures.some((entityFeature: string) => data?.teakFeatures?.includes(entityFeature));
	}
	function getAreaSections(area: any, parentsName: string | undefined) {
		const areaSections: Array<React.ReactElement> = [];
		const fullAreaName = parentsName ? `${parentsName} - ${area.name}` : area?.name;
		const availableEntities = area?.areaTeakEntities?.filter((entity: any) => isEntityAvailable(entity));
		if (availableEntities?.length > 0) {
			areaSections.push(
				<AreaSectionWithEntities
					key={area?._id}
					selectedEntity={selectedEntity}
					setSelectedEntity={setSelectedEntity}
					teakEntities={availableEntities}
					areaName={fullAreaName}
				/>,
			);
		}
		if (area?.children && area?.children?.length > 0) {
			area.children.forEach((child: any) => {
				areaSections.push(...getAreaSections(child, fullAreaName));
			});
		}
		return areaSections;
	}

	function getListContent() {
		if (loadingResults) {
			return (
				<div>
					<Spinner size="huge" label={`${t('common.loading')}...`} />
				</div>
			);
		}
		const areaSectionElements = getAreaSections(data.area, undefined);
		if (areaSectionElements.length > 0) {
			return areaSectionElements;
		} else {
			return (
				<div>
					<Subtitle1>{t('common.noResults')}</Subtitle1>
				</div>
			);
		}
	}

	function getDropdownAreaOption() {
		const options: IDropdownOption[] = [{key: 'all_areas', text: t('common.allAreas')}];
		if (!selectedOffice?.areasTree?.areas) {
			return options;
		}
		selectedOffice?.areasTree?.areas?.forEach((area: any) => {
			options.push({key: area._id, text: area.name});
		});
		return options;
	}

	function changeArea(newArea: string | number | undefined) {
		let selectedArea = newArea;
		if (typeof selectedArea === 'number') selectedArea = selectedArea.toString();
		if (selectedArea) {
			const {teakType, start, end, teakFeatures, meetingName} = data;
			searchResults(teakType, start, end, selectedArea, teakFeatures, meetingName);
		}
	}

	return (
		<React.Fragment>
			<div className="modal-content">
				<div className="teak-entity-list-view-header">
					<Subtitle1 as="h2" style={{margin: '0'}} id={'asset-type-label'}>
						{t('newBookingModal.pickA')} {teakTypeNameTranslated}
					</Subtitle1>
					<Dropdown
						className={dropdownStyles}
						aria-label={t('newBookingModal.locationAriaLabel') ?? 'Dropdown for selecting a location where to search for an asset'}
						placeholder={t('newBookingModal.locationPlaceholder') ?? 'Select location'}
						options={getDropdownAreaOption()}
						onChange={(e, option) => changeArea(option?.key)}
						selectedKey={area}
					/>
					{/* <Subtitle1>All areas: to do</Subtitle1> */}
				</div>
				<Body2 as="p" aria-label={t('newBookingModal.ariaLabelSelectedTimes') ?? 'selected date time'}>
					<FormattedDateTime dateTime={data.start} /> {' - '}
					<FormattedDateTime dateTime={data.end} />
				</Body2>
				<div className="teak-entity-list-view-wrapper">{getListContent()}</div>
			</div>
			<div className="modal-footer space-between-elements">
				<CommonButton onClickHandler={goBack} buttonId="back-modal-step-1" appearance="back" text={t('common.back')} />
				<CommonButton
					disabled={!selectedEntity}
					onClickHandler={() => confirmEntitySelection(selectedEntity)}
					buttonId="select-desk"
					appearance="primary"
					text={`${t('common.select')} ${teakTypeNameTranslated}`}
				/>
			</div>
		</React.Fragment>
	);
};

interface AreaSectionWithEntitiesProps {
	teakEntities: Array<any>;
	areaName: string | undefined;
	selectedEntity: any;
	setSelectedEntity: (entity: any) => void;
}

const AreaSectionWithEntities: React.FunctionComponent<AreaSectionWithEntitiesProps> = (props: AreaSectionWithEntitiesProps) => {
	const {t} = useTranslation();
	const {teakEntities, areaName, selectedEntity, setSelectedEntity} = props;
	return (
		<React.Fragment>
			<div className="area-section">
				<div className="area-section-header">
					<Subtitle2 as={'h3'}>{areaName}</Subtitle2>
					<Body2 as={'p'}>{`${teakEntities.length} ${teakEntities.length > 1 ? t('common.results') : t('common.result')}`}</Body2>
				</div>
				<ul className="teak-entity-list-view-content" aria-label={t('newBookingModal.ariaLabelListOfEntities') ?? 'List of entities found'}>
					{teakEntities?.map((entity: any, index: number) => {
						if (entity.status !== TEAK_ENTITY_STATUS.available) return;
						return (
							<li key={index}>
								<TeakEntityCard
									setIsSelected={() => setSelectedEntity({...entity, location: areaName})}
									isSelected={selectedEntity?._id === entity._id}
									entity={entity}
									areaName={areaName}
								/>
							</li>
						);
					})}
				</ul>
			</div>
		</React.Fragment>
	);
};
