import React from 'react';
import {ComboboxProps, Option} from '@fluentui/react-components';
import {useTranslation} from 'react-i18next';

import {updateMemberLanguage} from '../../api/member';
import {CommonCombobox} from '../inputs/CommonCombobox';
import {CurrentUserContext} from '../../pages/app/contexts/CurrentUserContext';
import {AlertContext} from '../../pages/app/contexts/AlertContext';

const languageOptions = [
	{value: 'en', label: 'English'},
	{value: 'fr', label: 'Français'},
	{value: 'de', label: 'Deutsch'},
	// {value: 'it', label: 'Italiano'},
	// {value: 'pt', label: 'Português'},
	// {value: 'nl', label: 'Nederlands'},
	// {value: 'es', label: 'Español'},
	// {value: 'fi', label: 'Finnish'},
];

function matchLanguage(language: string) {
	let label;
	switch (language) {
		case 'en-US':
			label = 'English';
			break;
		case 'en':
			label = 'English';
			break;
		case 'de':
			label = 'Deutsch';
			break;
		case 'fr':
			label = 'Français';
			break;
		case 'it':
			label = 'Italiano';
			break;
		case 'hi':
			label = 'Hindi';
			break;
		case 'ta':
			label = 'Tamil';
			break;
		case 'tam':
			label = 'Tamil Alphabet';
			break;
		case 'pt':
			label = 'Português';
			break;
		case 'nl':
			label = 'Nederlands';
			break;
		case 'es':
			label = 'Español';
			break;
		case 'fi':
			label = 'Finnish';
			break;
		default:
			label = 'English';
			break;
	}

	return label;
}

export const LanguageDrodpdown: React.FunctionComponent = () => {
	const {t, i18n} = useTranslation();
	const {currentUser} = React.useContext(CurrentUserContext);
	const {enableAlert} = React.useContext(AlertContext);
	const [languageSelection, setLanguageSelection] = React.useState<{option: string[]; value: string}>({option: [], value: ''});

	React.useEffect(() => {
		const currentLanguageOption = {option: [currentUser?.language], value: matchLanguage(currentUser?.language)};
		setLanguageSelection(currentLanguageOption);
	}, [currentUser]);

	const onLanguageChange: Partial<ComboboxProps>['onOptionSelect'] = async (event, data) => {
		i18n.changeLanguage(data.selectedOptions[0]);
		setLanguageSelection({option: data.selectedOptions, value: data.optionText ?? ''});
		try {
			await updateMemberLanguage(currentUser._id, data.selectedOptions[0]);
			enableAlert({show: true, intent: 'success', text: t('success.languageChangedSuccessfully')});
		} catch (error: any) {
			enableAlert({
				show: true,
				intent: 'error',
				text: 'errors.genericError',
			});
		}
	};

	return (
		<form name={t('header.formChangeLanguage') ?? 'Form for changing the language of the website'}>
			<CommonCombobox value={languageSelection.value} onOptionSelect={onLanguageChange} placeholder="Select a language">
				{languageOptions.map((option, index) => (
					<Option key={index} value={option.value}>
						{option.label}
					</Option>
				))}
			</CommonCombobox>
		</form>
	);
};
