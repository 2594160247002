import React, {useContext} from 'react';
import {makeStyles, shorthands, Text, Body1, CardFooter} from '@fluentui/react-components';
import {Card, CardHeader} from '@fluentui/react-components';
import {customTokens} from '../../utils/createTheme';
import {getFeatureNamesFromIds} from '../../utils/getFeatureNamesFromIds';
import {DashboardContext} from '../../pages/app/contexts/DashboardContext';

const useStyles = makeStyles({
	card: {
		...shorthands.border('2px', 'solid', customTokens.colorNeutralBackground1),
		...shorthands.borderRadius(customTokens.borderRadiusXXLarge),
		'&.f1kz6goq': {
			backgroundColor: customTokens.colorNeutralBackground1,
			...shorthands.border('2px', 'solid', customTokens.colorBrandForeground1),
			'&:hover': {
				backgroundColor: customTokens.colorNeutralBackground1Hover,
			},
		},
		'&:active': {
			backgroundColor: customTokens.colorNeutralBackground1Hover,
		},
	},
	iconBar: {
		display: 'flex',
		flexDirection: 'row',
	},
	iconBarItem: {
		color: customTokens.colorBrandForeground1,
		...shorthands.padding('0', '4px'),
	},
});

interface TeakEntityCardProps {
	entity: any;
	isSelected: boolean;
	setIsSelected: () => void;
	areaName?: string;
}

export const TeakEntityCard: React.FunctionComponent<TeakEntityCardProps> = (props: TeakEntityCardProps) => {
	const {entity, isSelected, setIsSelected, areaName} = props;
	// const desk_icon = `${process.env.PUBLIC_URL}/images/desk_icon.svg`;
	const djLogo = `${process.env.PUBLIC_URL}/images/logo_icon.svg`;
	const styles = useStyles();
	const {selectedOffice} = useContext(DashboardContext);

	return (
		<Card className={styles.card} selected={isSelected} onSelectionChange={setIsSelected}>
			<CardHeader
				image={<img style={{width: '24px', height: '24px'}} src={djLogo} alt="" />}
				header={<Text weight="semibold">{entity?.name}</Text>}
				// action={<Button appearance="transparent" icon={<Heart20Regular />} aria-label="Mark as favourite" />}
			/>
			<div className="entity-features-wrapper">
				{getFeatureNamesFromIds(entity?.teakFeatures, selectedOffice?.teakFeatures)?.map((featureName, index) => (
					<div key={index} className="feature-label">
						<Body1 as="p">{featureName}</Body1>
					</div>
				))}
			</div>
			<CardFooter aria-label="More options">
				<Text>{areaName}</Text>
			</CardFooter>
		</Card>
	);
};
