import axios from 'axios';

const teakBookingUrls = {
	teakBooking: '/biro/teak/booking',
	findInRange: '/findInRange',
	checkInNow: '/checkInNow',
	checkOutNow: '/checkOutNow',
	editTeakBooking: '/edit',
};

/**
 * @param {string} string = start time of the range in which to get the bookings
 * @param {string} end = end time of the range in which to get the bookings
 * @returns The route request will get all available teak entities inside an area
 */
export async function findBookingsInRange(start: string | null, end: string | null) {
	const spacestationUrl: string = process.env.REACT_APP_ENGINE_HOST_URL as string;
	const requestUrl = `${spacestationUrl + teakBookingUrls.teakBooking + teakBookingUrls.findInRange}`;

	return axios({
		method: 'post',
		url: requestUrl,
		data: {
			start,
			end,
		},
	});
}

/**
 * @param {string} teakBookingId = id of the booking the user wants to check into
 * @returns The route request will checkin the user into the selected booking
 */
export async function checkInNow(teakBookingId: string) {
	const spacestationUrl: string = process.env.REACT_APP_ENGINE_HOST_URL as string;
	const requestUrl = `${spacestationUrl + teakBookingUrls.teakBooking}/${teakBookingId + teakBookingUrls.checkInNow}`;

	return axios({
		method: 'post',
		url: requestUrl,
	});
}

/**
 * @param {string} teakBookingId = id of the booking the user wants to check out of
 * @returns The route request will check the user out of the selected booking
 */
export async function checkOutNow(teakBookingId: string) {
	const spacestationUrl: string = process.env.REACT_APP_ENGINE_HOST_URL as string;
	const requestUrl = `${spacestationUrl + teakBookingUrls.teakBooking}/${teakBookingId + teakBookingUrls.checkOutNow}`;

	return axios({
		method: 'post',
		url: requestUrl,
	});
}

/**
 * @param {string} teakBookingId = id of the booking the user wants to cancel
 * @returns The route request will cancel the selected booking
 */
export async function cancelBooking(teakBookingId: string) {
	const spacestationUrl: string = process.env.REACT_APP_ENGINE_HOST_URL as string;
	const requestUrl = `${spacestationUrl + teakBookingUrls.teakBooking}/${teakBookingId}`;

	return axios({
		method: 'put',
		url: requestUrl,
	});
}

/**
 * @param {string} teakBookingId = id of the booking the user wants to edit
 * @param {ISO} start = new start time in iso format - UTC format
 * @param {ISO} end = new end time in iso format - UTC format
 * @returns The route request will edit the selected teak booking
 */
export async function editTeakBooking(teakBookingId: string, start: string | null, end: string | null) {
	const spacestationUrl: string = process.env.REACT_APP_ENGINE_HOST_URL as string;
	const requestUrl = `${spacestationUrl + teakBookingUrls.teakBooking}/${teakBookingId + teakBookingUrls.editTeakBooking}`;

	return axios({
		method: 'post',
		url: requestUrl,
		data: {
			start,
			end,
		},
	});
}
