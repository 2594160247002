import {Body1, Body1Strong, Button, Subtitle1, Title3} from '@fluentui/react-components';
import {useTranslation} from 'react-i18next';
import React, {useContext} from 'react';
import {DateTime} from 'luxon';

import {FormattedDateTime} from '../../../components/dateTime/FormattedDateTime';
import {getFeatureNamesFromIds} from '../../../utils/getFeatureNamesFromIds';
import {getAreaNameFromId} from '../../../utils/getAreaNameFromId';
import {teakTypeNamesMap} from '../../../constants/teakTypeNamesMap';
import {customTokens} from '../../../utils/createTheme';
import {DashboardContext} from '../../app/contexts/DashboardContext';
import {ModalContext} from '../../app/contexts/ModalContext';

export const BookingDetailsModal: React.FunctionComponent = () => {
	const {t} = useTranslation();
	const {selectedOffice} = useContext(DashboardContext);
	const {enableModal, modalOptions} = useContext(ModalContext);
	const bookingData = modalOptions.modalData;
	const teakTypeName: string | undefined = teakTypeNamesMap.get(bookingData?.teakEntity?.__t);
	const teakTypeNameTranslated: string = teakTypeName ? t(teakTypeName) : t('teakTypes.generic');

	function closeModal() {
		enableModal(false, undefined, undefined);
	}

	return (
		<div className="modal-wrapper booking-details-modal">
			<div className="modal-header">
				<Title3 as="h1">{t('common.bookingDetails')}</Title3>
				<Button style={{minWidth: '0', color: customTokens.colorBrandBackground, border: 'none'}} onClick={closeModal}>
					X
				</Button>
			</div>
			<div className="modal-content">
				<Subtitle1 as="h2" id={'booking-details'}>
					{t('common.bookingSummary')}
				</Subtitle1>
				<div className="booking-detail-info">
					<Body1Strong as="p" className="value-label">
						{teakTypeNameTranslated}
					</Body1Strong>
					<Body1 as="p">{bookingData?.teakEntity?.name}</Body1>
				</div>
				{bookingData?.meetingName && (
					<div className="booking-detail-info">
						<Body1Strong as="p" className="value-label">
							{t('common.meetingName')}
						</Body1Strong>
						<Body1 as="p">{bookingData?.meetingName}</Body1>
					</div>
				)}
				<div className="booking-detail-info">
					<Body1Strong as="p" className="value-label">
						{t('common.location')}
					</Body1Strong>
					<Body1 as="p">{getAreaNameFromId(bookingData?.teakEntity?.area, selectedOffice?.areas)}</Body1>
				</div>
				<div className="booking-detail-info">
					<Body1Strong as="p" className="value-label">
						{t('common.office')}
					</Body1Strong>
					<Body1 as="p">{selectedOffice?.name}</Body1>
				</div>
				<div className="booking-detail-info">
					<Body1Strong as="p" className="value-label">
						{t('common.from')}
					</Body1Strong>
					<Body1 as="p">
						<FormattedDateTime dateTime={DateTime.fromISO(bookingData.start.time)} />
					</Body1>
				</div>
				<div className="booking-detail-info">
					<Body1Strong as="p" className="value-label">
						{t('common.to')}
					</Body1Strong>
					<Body1 as="p">
						<FormattedDateTime dateTime={DateTime.fromISO(bookingData.end.time)} />
					</Body1>
				</div>
				{bookingData?.teakEntity?.teakFeatures?.length > 0 && (
					<div className="booking-detail-info">
						<Body1Strong as="p" className="value-label">
							{t('common.features')}
						</Body1Strong>
						<div className="entity-features-wrapper ">
							{getFeatureNamesFromIds(bookingData?.teakEntity?.teakFeatures, selectedOffice?.teakFeatures)?.map(
								(featureName, index) => (
									<div key={index} className="feature-label">
										<Body1 as="p">{featureName}</Body1>
									</div>
								),
							)}
						</div>
					</div>
				)}
			</div>
			<div className="modal-footer center-elements"></div>
		</div>
	);
};
