/**
 *
 * @param areaId = area id for which to get name
 * @param officeAreas = list of areas assigned to the selected office
 * @returns the name of the area based on the id
 */
export function getAreaNameFromId(areaId: string, officeAreas: Array<any>) {
	const area = officeAreas.find((area: any) => area?._id === areaId);
	return area ? area?.name : 'no area';
}
