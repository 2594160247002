import {getAreas} from '../api/area';
import {getTeakFeatures} from '../api/teakFeatures';
import {getTeakTypes} from '../api/teakTypes';
import {TEAK_TYPE_NAMES} from '../constants/teakTypeNames';

function getFlatAreaList(areas: Array<any>) {
	const areaFlatList: Array<any> = [];
	areas.forEach((area: any) => {
		areaFlatList.push(area);
		if (area?.children?.length > 0) {
			areaFlatList.push(...getFlatAreaList(area.children));
		}
	});

	return areaFlatList;
}

function structureTeakTypes(teakTypes: Array<any>, office: string) {
	if (!teakTypes) return [];
	const teakTypesForOffice = {
		workplace: null,
		meetingRoom: null,
		parkingSpace: null,
		office: office,
	};
	teakTypes.forEach((teakType: any) => {
		if (teakType.preset === 'workplace' || teakType.__t === TEAK_TYPE_NAMES.DESK) {
			teakTypesForOffice.workplace = teakType;
		} else if (teakType.preset === 'meetingRoom' || teakType.__t === TEAK_TYPE_NAMES.ROOM) {
			teakTypesForOffice.meetingRoom = teakType;
		} else if (teakType.preset === 'parkingSpace' || teakType.__t === TEAK_TYPE_NAMES.PARKING) {
			teakTypesForOffice.parkingSpace = teakType;
		}
	});

	return teakTypesForOffice;
}
export async function getDataForOffice(office: string) {
	try {
		//get area list for office
		const areaResponse = await getAreas(office);

		const areasTreeList = {officeId: office, areas: areaResponse.data};
		const areasList: Array<any> = [];
		if (areaResponse.data?.length > 0) {
			areasList.push(...getFlatAreaList(areaResponse.data));
		}

		//get teak feature list for office
		const teakFeaturesArray: Array<any> = [];
		const manyFeaturesForOfficeResponse = await getTeakFeatures(office);
		if (manyFeaturesForOfficeResponse?.data && manyFeaturesForOfficeResponse?.data?.length > 0) {
			manyFeaturesForOfficeResponse.data.forEach((feature: any) => teakFeaturesArray.push({...feature, id: feature._id}));
		}

		// get teak type list for office
		const teakTypeResponse = await getTeakTypes(office);
		const teakTypeForOffice = structureTeakTypes(teakTypeResponse.data, office);
		return {
			areasTree: areasTreeList,
			areas: areasList,
			teakFeatures: teakFeaturesArray,
			teakTypes: teakTypeForOffice,
		};
	} catch (error) {
		console.log(error);
		return;
	}
}
