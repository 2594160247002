import axios from 'axios';

axios.defaults.withCredentials = true;

const officeUrls = {
	office: '/biro/office',
	findMany: '/findMany',
	grouped: '/grouped',
};

/**
 * @returns The route request will get all the offices that this member has access to.
 */
export function getManyOffices() {
	const spacestationUrl: string = process.env.REACT_APP_ENGINE_HOST_URL as string;
	const requestUrl = `${spacestationUrl + officeUrls.office + officeUrls.findMany}`;

	return axios({
		method: 'post',
		url: requestUrl,
	});
}

/**
 * @returns The route request will get all the offices that this member has access to grouped by their country. This is used for the initial office selection
 */
export function getOfficesGrouped() {
	const spacestationUrl: string = process.env.REACT_APP_ENGINE_HOST_URL as string;
	const requestUrl = `${spacestationUrl + officeUrls.office + officeUrls.findMany + officeUrls.grouped}`;

	return axios({
		method: 'get',
		url: requestUrl,
	});
}
