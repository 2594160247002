import {Link} from '@fluentui/react';
import {Body1} from '@fluentui/react-components';
import React, {useEffect, useState} from 'react';
import {Trans, useTranslation} from 'react-i18next';

interface ResendTimerProps {
	resendCallback: () => void;
	infoTextClass: string;
}
const timerInSeconds = 30;
export const ResendTimer: React.FunctionComponent<ResendTimerProps> = (props: ResendTimerProps) => {
	const {t} = useTranslation();
	const {resendCallback, infoTextClass} = props;
	const [{timer, startTimer}, setTimerState] = useState({timer: timerInSeconds, startTimer: false});
	useEffect(() => {
		if (startTimer) {
			const interval = setInterval(() => {
				if (timer > 0) {
					setTimerState((currentState) => ({...currentState, timer: currentState.timer - 1}));
				} else {
					setTimerState((currentState) => ({...currentState, startTimer: false}));
				}
			}, 1000);
			return () => clearInterval(interval);
		}
	}, [startTimer, timer]);

	function resendOTP() {
		resendCallback();
		setTimerState({timer: timerInSeconds, startTimer: true});
	}
	return (
		<React.StrictMode>
			<div className="resend-timer">
				<Body1 className={infoTextClass} as="h3">
					{t('login.otp.didntReceiveCode')}{' '}
				</Body1>
				{startTimer ? (
					<Body1 className={infoTextClass}>
						<Trans
							i18nKey="login.otp.tryAgain"
							values={{nbOfSeconds: timer}}
							components={{
								span: <span style={{fontWeight: 'bold'}} />,
							}}
						/>
					</Body1>
				) : (
					<Link as={'button'} onClick={resendOTP}>
						{t('login.otp.resend')}
					</Link>
				)}
			</div>
		</React.StrictMode>
	);
};
