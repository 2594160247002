import {Link} from '@fluentui/react';
import {useTranslation} from 'react-i18next';
import {Body1, Body1Strong, Subtitle1, Title1, Title2} from '@fluentui/react-components';
import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {CommonButton} from '../../components/button/CommonButton';
import {CommonInput} from '../../components/inputs/CommonInput';
import {ROUTES} from '../../constants/routes';
import {getAuthContext, getLoginUrl, validateAuthCode} from '../../api/member';

export const Login: React.FunctionComponent = () => {
	const {t} = useTranslation();
	const doorjamesLogoUrl = `${process.env.PUBLIC_URL}/images/DOORJAMES_LOGO.svg`;
	const backgroundImage = `${process.env.PUBLIC_URL}/images/login_background.svg`;
	const queryString = window.location.search;
	const urlParams = new URLSearchParams(queryString);
	const organizationFromURL = urlParams.get('organization');
	const authCode = urlParams.get('code');
	const [{email, errorEmail, loading}, setState] = React.useState<{email: string; errorEmail: any; loading: boolean}>({
		email: '',
		errorEmail: null,
		loading: false,
	});
	const navigate = useNavigate();
	///Help Desk URL
	const helpDeskURL = 'https://help.doorjames.com/index.php?a=add&category=14';
	//Door James Website URL
	const websiteUrl = 'https://www.cgi.com/en/solutions/intelligence-automation/door-james';

	async function loginWithThirdParty(strategy: string, organization: string) {
		try {
			const loginUrlResponse = await getLoginUrl(strategy, email);
			window.localStorage.setItem('organization', organization);
			window.localStorage.setItem('loginStrategy', strategy);
			window.location.assign(loginUrlResponse?.data);
		} catch (error) {
			throw Error('error while getting the login url');
		}
	}

	const checkEmptyString = (value: string) => {
		const whiteSpaceRegex = /\s/g;
		if (value === '') return true;
		return whiteSpaceRegex.test(value);
	};

	const isValidEmail = (value: string) => {
		if (value === null) return false;
		const emailRegex = /\S+@\S+\.\S+/;
		return emailRegex.test(value);
	};

	const checkEmailAddress = (value: string) => {
		if (checkEmptyString(value)) {
			return false;
		} else if (isValidEmail(value)) {
			return true;
		} else {
			return false;
		}
	};
	async function checkEmail() {
		setState((current) => ({...current, loading: true}));
		if (!checkEmailAddress(email)) {
			setState((current) => ({...current, errorEmail: 'Please insert a valid email address.', loading: false}));
			return;
		}
		try {
			const authContext = await getAuthContext(organizationFromURL, email.split('@')[1]);
			const authContextData = authContext.data;
			if (!authContextData[0]) {
				throw Error('no organization found');
			}
			const organization = authContextData[0];
			if (organization.strategy === 'otp') {
				navigate(ROUTES.OTP, {state: {email: email, organizationId: organization?._id}});
			} else {
				loginWithThirdParty(organization.strategy, organization?._id);
			}
			setState((current) => ({...current, loading: false}));
		} catch (error) {
			console.log(error);
			setState((current) => ({...current, errorEmail: 'There was an error with the email', loading: false}));
		}
	}

	async function checkAuthCode() {
		const organization = window.localStorage.getItem('organization');
		const strategy = window.localStorage.getItem('loginStrategy');
		if (!organization || !strategy) {
			console.log('no organization or strategy found');
			return;
		}

		try {
			const validateResponse = await validateAuthCode(queryString, strategy, organization);
			window.localStorage.setItem('memberId', validateResponse.data.member);

			const redirectUri = window.localStorage.getItem('redirectUriLogin') ?? ROUTES.DEFAULT;
			window.localStorage.removeItem('redirectUriLogin');
			window.localStorage.removeItem('loginStrategy');
			window.localStorage.removeItem('organization');
			return window.location.assign(redirectUri);
		} catch (error) {
			window.localStorage.removeItem('loginStrategy');
			window.localStorage.removeItem('organization');
			return window.location.assign(ROUTES.DEFAULT);
		}
	}

	useEffect(() => {
		// if there is an auth code, third party redirected back to our app and we need to validate the code
		if (authCode) {
			checkAuthCode();
		} else {
			if (window.location.pathname !== '/login') {
				window.localStorage.setItem('redirectUriLogin', window.location.pathname);
			}
		}
	}, []);
	return (
		<React.StrictMode>
			<div className="login-page intro-page">
				<div className="login-page-content intro-page-content">
					<div className="login-page-content-details intro-page-content-details">
						<img className="doorjames-logo" src={doorjamesLogoUrl} alt="Door James" />
						<Title1 as="h1" className="header">
							{' '}
							{t('login.hiThere')}{' '}
						</Title1>
						<Title2 as="h2" className="header">
							{' '}
							{t('login.welcome')}{' '}
						</Title2>
						<Subtitle1 as="h3" className="header">
							{' '}
							{t('login.appInfo')}
						</Subtitle1>
						<CommonInput
							placeholder={t('common.emailPlaceholder')}
							inputId="login-email-address"
							label={t('common.email')}
							ariaLabel={t('login.ariaLabelEmail')}
							error={errorEmail}
							value={email}
							onChangeHandler={(e) => setState((current) => ({...current, email: e.target.value}))}
							size="large"
						/>
						<CommonButton
							size={'large'}
							appearance="primary"
							onClickHandler={checkEmail}
							loading={loading}
							buttonId="login-button"
							text={t('login.login')}
						/>
						<Body1Strong>
							{t('login.cantLogin')}{' '}
							<Link target="_blank" href={helpDeskURL}>
								{t('login.clickHere')}
							</Link>{' '}
						</Body1Strong>
					</div>

					<div className="login-page-content-copyright">
						<Body1Strong>
							{t('login.clickHere')}{' '}
							<Link target="_blank" href={websiteUrl}>
								{t('login.visitWebsite')}
							</Link>{' '}
						</Body1Strong>
						<div className="cgi-logo-wrapper">
							<Body1>{t('login.solutionBy')}</Body1>
							<img className={'cgi-logo'} alt="CGI" src={process.env.PUBLIC_URL + '/images/cgi_logo.svg'} />
						</div>
						<Body1>{t('login.copyright')}</Body1>
					</div>
				</div>
				<div className="login-page-image-wrapper intro-page-image-wrapper">
					<img src={backgroundImage} alt="" />
				</div>
			</div>
		</React.StrictMode>
	);
};
