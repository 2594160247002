import React, {useContext} from 'react';
import {Image, makeStyles, shorthands} from '@fluentui/react-components';
import {Alert, AlertProps} from '@fluentui/react-components/unstable';
import {useTranslation} from 'react-i18next';

import {customTokens} from '../../utils/createTheme';
import {DismissCircle12Regular, DismissCircle28Regular} from '@fluentui/react-icons';
import {AlertContext} from '../../pages/app/contexts/AlertContext';

type CommonAlertProps = AlertProps & {
	// Define additional props for the custom Alert component here
};

const useStyles = makeStyles({
	error: {
		position: 'fixed',
		right: 0,
		zIndex: 150,
		backgroundColor: customTokens.colorRedLight,
		...shorthands.border('1.5px', 'solid', customTokens.colorRed),
	},
	success: {
		position: 'fixed',
		right: 0,
		zIndex: 150,
		backgroundColor: customTokens.colorGreenLight,
		...shorthands.border('1.5px', 'solid', customTokens.colorGreen),
	},
});

export const CommonAlert: React.FunctionComponent<CommonAlertProps> = (props) => {
	const {alert, enableAlert} = useContext(AlertContext);
	const error_icon = `${process.env.PUBLIC_URL}/images/error_icon.svg`;
	const success_icon = `${process.env.PUBLIC_URL}/images/success_icon.svg`;
	const {children, className, intent, ...rest} = props;
	const styles = useStyles();
	const {t} = useTranslation();

	let icon;
	let intentStyle;

	switch (alert?.intent) {
		case 'error':
			icon = <Image src={error_icon} alt="Error" />;
			intentStyle = styles.error;
			break;
		case 'success':
			icon = <Image src={success_icon} alt="Success" />;
			intentStyle = styles.success;
			break;
		default:
			break;
	}

	if (!alert?.show) return null;

	return (
		<Alert
			className={`${className} ${intentStyle}`}
			icon={icon}
			intent={intent}
			{...rest}
			action={{
				icon: <DismissCircle28Regular onClick={() => enableAlert(false)} />,
			}}
		>
			{t(alert.text)}
		</Alert>
	);
};
