import React, {createContext, useState} from 'react';
import {DateTime} from 'luxon';

import {findBookingsInRange} from '../../../api/teakBooking';
import {ContextProviderProps} from './contextInterface';

interface DashboardContextType {
	loadingDashboard: boolean;
	offices: Array<any>;
	bookings: Array<any>;
	selectedOffice: any;
	updateLoadingDashboard: (loading: boolean) => void;
	updateOffices: (newList: Array<any>) => void;
	updateSelectedOffice: (newOffice: any) => void;
	loadBookings: () => void;
}

export const DashboardContext = createContext<DashboardContextType>({
	loadingDashboard: false,
	updateLoadingDashboard: () => console.log('office loading state updated'),
	offices: [],
	updateOffices: () => console.log('office array updated'),
	selectedOffice: {},
	updateSelectedOffice: () => console.log('selected office updated'),
	bookings: [],
	loadBookings: () => console.log('selected office updated'),
});

export const DashboardContextProvider: React.FunctionComponent<ContextProviderProps> = (props: ContextProviderProps) => {
	const {children} = props;
	const [loadingDashboard, setLoadingDashboard] = useState(false);
	const [offices, setOffices] = useState([]);
	const [selectedOffice, setSelectedOffice] = useState(null);
	const [bookings, setBookings] = React.useState<Array<any>>([]);
	const nbOfDaysAhead = 14; //nb of days ahead to get next bookings
	const nbOfDaysBefore = 14; //nb of days before to get past bookings

	async function loadBookings() {
		setLoadingDashboard(true);
		try {
			const start = DateTime.now().minus({days: nbOfDaysBefore}).toUTC().toISO();
			const end = DateTime.now().plus({days: nbOfDaysAhead}).toUTC().toISO();
			const bookings = await findBookingsInRange(start, end);
			setBookings(bookings.data);
			setLoadingDashboard(false);
		} catch (error) {
			console.log(error);
			setLoadingDashboard(false);
		}
	}
	return (
		<DashboardContext.Provider
			value={{
				loadingDashboard,
				offices,
				selectedOffice,
				bookings,
				updateLoadingDashboard: (loading: boolean) => setLoadingDashboard(loading),
				updateOffices: (offices: any) => setOffices(offices),
				updateSelectedOffice: (newSelectedOffice: any) => setSelectedOffice(newSelectedOffice),
				loadBookings: () => loadBookings(),
			}}
		>
			{children}
		</DashboardContext.Provider>
	);
};
