import React from 'react';
import {Route, Routes} from 'react-router-dom';
import {Dashboard} from '../dashboard/Dashboard';

export const Router: React.FunctionComponent = () => {
	return (
		<React.StrictMode>
			<Routes>
				<Route index path="/*" element={<Dashboard />} />
			</Routes>
		</React.StrictMode>
	);
};
