import {Body1, Body1Strong, Spinner, Subtitle1} from '@fluentui/react-components';
import React, {useContext} from 'react';
import {useTranslation} from 'react-i18next';

import {CommonButton} from '../../../components/button/CommonButton';
import {FormattedDateTime} from '../../../components/dateTime/FormattedDateTime';
import {bookTeakEntity} from '../../../api/teakEntity';
import {getFeatureNamesFromIds} from '../../../utils/getFeatureNamesFromIds';
import {teakTypeNamesMap} from '../../../constants/teakTypeNamesMap';
import {DashboardContext} from '../../app/contexts/DashboardContext';
import {ModalContext} from '../../app/contexts/ModalContext';
import {AlertContext} from '../../app/contexts/AlertContext';

interface BookingSummaryModalProps {
	goBack: () => void;
	data?: any;
}
export const BookingSummaryModal: React.FunctionComponent<BookingSummaryModalProps> = (props: BookingSummaryModalProps) => {
	const {goBack, data} = props;
	const {t} = useTranslation();
	const {selectedOffice, loadBookings} = useContext(DashboardContext);
	const {enableModal} = useContext(ModalContext);
	const {enableAlert} = useContext(AlertContext);
	const [loadingResults, setLoadingResults] = React.useState(false);
	const teakTypeName: string | undefined = teakTypeNamesMap.get(data?.teakEntity?.__t);
	const teakTypeNameTranslated: string = teakTypeName ? t(teakTypeName) : t('teakTypes.generic');
	const meetingName =
		data?.meetingName !== ''
			? data?.meetingName
			: data?.teakType?.preset === 'meetingRoom'
			? `Meeting in ${data?.selectedEntity?.name}`
			: undefined;

	async function confirmBookingHandler() {
		setLoadingResults(true);
		try {
			const startTime = data?.start?.toUTC().toISO();
			const endTime = data?.end?.toUTC().toISO();
			await bookTeakEntity(data?.selectedEntity?._id, startTime, endTime, meetingName);
			setLoadingResults(false);
			enableModal(false, undefined, undefined);
			enableAlert({show: true, intent: 'success', text: 'success.bookingCreated'});
			loadBookings();
		} catch (error: any) {
			console.log(error);
			enableAlert({
				show: true,
				intent: 'error',
				text: error?.response?.data?.name ? `errors.bookingErrors.${error.response.data.name}` : 'genericError',
			});
			setLoadingResults(false);
		}
	}

	return (
		<React.Fragment>
			<div className="modal-content">
				<Subtitle1 as="h2" id={'booking-details'}>
					{t('common.bookingDetails')}
				</Subtitle1>
				<div className="booking-detail-info">
					<Body1Strong as="p" className="value-label">
						{teakTypeNameTranslated}
					</Body1Strong>
					<Body1 as="p">{data?.selectedEntity?.name}</Body1>
				</div>
				{meetingName && (
					<div className="booking-detail-info">
						<Body1Strong as="p" className="value-label">
							{t('common.meetingName')}
						</Body1Strong>
						<Body1 as="p">{meetingName}</Body1>
					</div>
				)}
				<div className="booking-detail-info">
					<Body1Strong as="p" className="value-label">
						{t('common.location')}
					</Body1Strong>
					<Body1 as="p">{data?.selectedEntity?.location}</Body1>
				</div>
				<div className="booking-detail-info">
					<Body1Strong as="p" className="value-label">
						{t('common.office')}
					</Body1Strong>
					<Body1 as="p">{selectedOffice?.name}</Body1>
				</div>
				<div className="booking-detail-info">
					<Body1Strong as="p" className="value-label">
						{t('common.from')}
					</Body1Strong>
					<Body1 as="p">
						<FormattedDateTime dateTime={data.start} />
					</Body1>
				</div>
				<div className="booking-detail-info">
					<Body1Strong as="p" className="value-label">
						{t('common.to')}
					</Body1Strong>
					<Body1 as="p">
						<FormattedDateTime dateTime={data.end} />
					</Body1>
				</div>
				{data?.selectedEntity?.teakFeatures?.length > 0 && (
					<div className="booking-detail-info">
						<Body1Strong as="p" className="value-label">
							{t('common.features')}
						</Body1Strong>
						<div className="entity-features-wrapper ">
							{getFeatureNamesFromIds(data?.selectedEntity?.teakFeatures, selectedOffice?.teakFeatures)?.map((featureName, index) => (
								<div key={index} className="feature-label">
									<Body1 as="p">{featureName}</Body1>
								</div>
							))}
						</div>
					</div>
				)}
			</div>
			<div className="modal-footer space-between-elements">
				<CommonButton onClickHandler={goBack} buttonId="back-modal-step-1" appearance="back" text={t('common.back')} />
				{loadingResults ? (
					<Spinner size="extra-small" label={t('common.loadingResults')} />
				) : (
					<CommonButton
						buttonId="search-results-form"
						appearance="primary"
						onClickHandler={confirmBookingHandler}
						text={t('common.confirmBooking')}
					/>
				)}
			</div>
		</React.Fragment>
	);
};
