import {Body2, Button, Title3} from '@fluentui/react-components';
import {useTranslation} from 'react-i18next';
import {Warning24Regular} from '@fluentui/react-icons';
import React, {useContext} from 'react';

import {cancelBooking} from '../../../api/teakBooking';
import {CommonButton} from '../../../components/button/CommonButton';
import {customTokens} from '../../../utils/createTheme';
import {ModalContext} from '../../app/contexts/ModalContext';
import {DashboardContext} from '../../app/contexts/DashboardContext';
import {AlertContext} from '../../app/contexts/AlertContext';

export const CancelBookingModal: React.FunctionComponent = () => {
	const {t} = useTranslation();
	const {enableModal, modalOptions} = useContext(ModalContext);
	const {loadBookings} = useContext(DashboardContext);
	const {enableAlert} = useContext(AlertContext);

	async function confirmCancelBooking() {
		try {
			await cancelBooking(modalOptions.modalData._id);
			closeModal();
			loadBookings();
			enableAlert({show: true, intent: 'success', text: t('success.bookingCancelled')});
		} catch (error: any) {
			console.log(error);
			enableAlert({
				show: true,
				intent: 'error',
				text: error?.response?.data?.name ? `errors.cancelBookingErrors.${error.response.data.name}` : 'genericError',
			});
		}
	}

	function closeModal() {
		enableModal(false, undefined, undefined);
	}
	return (
		<div className="modal-wrapper cancel-booking-modal">
			<div className="modal-header">
				<Title3 as="h1">{t('cancelBookingModal.confirmText')}</Title3>
				<Button style={{minWidth: '0'}} onClick={closeModal}>
					X
				</Button>
			</div>
			<div className="modal-content">
				<Warning24Regular className="warning-icon" primaryFill={customTokens.colorRedHover}></Warning24Regular>
				<Body2 as={'p'} style={{textAlign: 'center'}}>
					{t('cancelBookingModal.confirmInfo')}
				</Body2>
			</div>
			<div className="modal-footer center-elements">
				<CommonButton
					onClickHandler={confirmCancelBooking}
					buttonId="cancel-booking-confirm"
					appearance="danger"
					text={t('common.cancelBooking')}
				/>
				<CommonButton onClickHandler={closeModal} buttonId="cancel-action" appearance="secondary" text={t('common.back')} />
			</div>
		</div>
	);
};
