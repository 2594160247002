import axios from 'axios';
import {Profile} from '../pages/profileCompletion/ProfileCompletion';

axios.defaults.withCredentials = true;

const memberUrls = {
	auth: '/biro/auth',
	context: '/context',
	strategy: '/strategy',
	strategyOtp: '/otp',
	otpGenerate: '/generate',
	validate: '/validate',
	member: '/biro/member',
	url: '/url',
	logout: '/logout',
};

/**
 *
 * @param {string} organization = organization id, usually coming from scanning a qr code.
 * @param {string} identityDomain = identity domain of the email added to the login input.
 * @returns The route request either an organization or identityDomain!
 */
export function getAuthContext(organization: string | null, identityDomain: string) {
	const spacestationUrl: string = process.env.REACT_APP_ENGINE_HOST_URL as string;

	let requestUrl = spacestationUrl + memberUrls.auth + memberUrls.context;

	if (organization) requestUrl = `${requestUrl}?organization=${organization}`;
	if (identityDomain) requestUrl = `${requestUrl}${organization ? '&' : '?'}identityDomain=${identityDomain}`;

	return axios({
		method: 'post',
		url: requestUrl,
	});
}

/**
 *
 * @param {string} strategy = strategy for which to get a login url
 * @param {string} email = email for which to create a login url
 * @returns The route request either an organization or identityDomain!
 */
export function getLoginUrl(strategy: string, email: string) {
	const spacestationUrl: string = process.env.REACT_APP_ENGINE_HOST_URL as string;
	const loginStrategyBaseURL = spacestationUrl + memberUrls.auth + memberUrls.strategy + '/';
	const requestUrl = `${loginStrategyBaseURL + strategy + memberUrls.url}?loginHint=${email}`;

	return axios({
		method: 'get',
		url: requestUrl,
	});
}

/**
 *
 * @param {string} organization = organization id used to generate otp code
 * @param {string} email = email to generate the otp code for
 * @returns The route request will generate an OTP for the user. OTP code is sent by email.
 */
export async function generateOTP(email: string, organization: string) {
	const satelliteUrl = window.localStorage.getItem('satelliteUrl');
	const hostUrl = satelliteUrl ? satelliteUrl : process.env.REACT_APP_ENGINE_HOST_URL;
	const requestUrl = `${hostUrl + memberUrls.auth + memberUrls.strategy + memberUrls.strategyOtp + memberUrls.otpGenerate}`;

	return axios({
		method: 'get',
		url: requestUrl,
		params: {email, organization},
	});
}

/**
 *
 * @param {string} email = email to check the otp for
 * @param {string} otp = otp to be checked
 * @returns The route request will validate the OTP inserted by the user
 */
export async function validateOTP(email: string, otp: number) {
	const satelliteUrl = window.localStorage.getItem('satelliteUrl');
	const hostUrl = satelliteUrl ? satelliteUrl : process.env.REACT_APP_ENGINE_HOST_URL;
	const requestUrl = `${hostUrl + memberUrls.auth + memberUrls.strategy + memberUrls.strategyOtp + memberUrls.validate}`;

	return axios({
		method: 'get',
		url: requestUrl,
		params: {email, otp},
	});
}

/**
 *
 * @param {string} queryString = query string that includes the code ?code= + some additionals query strings needed for the BE = such as identityDomain or organization
 * @param {string} strategy = third party strategy used to log in
 * @param {string} organization = organization in which the user tries to login
 * @returns The route request will validate the auth code received from the callback after the third party sign in
 */
export async function validateAuthCode(queryString: string, strategy: string, organization: string) {
	const satelliteUrl = window.localStorage.getItem('satelliteUrl');
	const hostUrl = satelliteUrl ? satelliteUrl : process.env.REACT_APP_ENGINE_HOST_URL;
	const requestUrl = `${hostUrl + memberUrls.auth + memberUrls.strategy}/${strategy}/callback${queryString}&organization=${organization}`;

	return axios({
		method: 'get',
		url: requestUrl,
	});
}

/**
 *
 * @param {string} memberId = member id of logged in member
 * @returns The route request will get the member object with this memberid
 */
export async function getMember(memberId: string) {
	const satelliteUrl = window.localStorage.getItem('satelliteUrl');
	const hostUrl = satelliteUrl ? satelliteUrl : process.env.REACT_APP_ENGINE_HOST_URL;
	const requestUrl = `${hostUrl + memberUrls.member}/${memberId}`;

	return axios({
		method: 'get',
		url: requestUrl,
	});
}

/**
 *
 * @param {string} memberId = member id of logged in member
 * @param {string} officeId = new office id to change to
 * @returns The route request will set the selected office for the member
 */
export async function updateUserOffice(memberId: string, officeId: string | number) {
	const satelliteUrl = window.localStorage.getItem('satelliteUrl');
	const hostUrl = satelliteUrl ? satelliteUrl : process.env.REACT_APP_ENGINE_HOST_URL;
	const requestUrl = `${hostUrl + memberUrls.member}/${memberId}`;

	return axios({
		method: 'put',
		url: requestUrl,
		data: {
			update: {
				biro: {
					lastOfficeSelection: officeId,
				},
			},
		},
	});
}

/**
 *
 * @param {string} memberId = member id of logged in member
 * @param {string} language = new language code to be updated for memberDoc
 * @returns The route request will set the selected office for the member
 */
export async function updateMemberLanguage(memberId: string, language: string) {
	const satelliteUrl = window.localStorage.getItem('satelliteUrl');
	const hostUrl = satelliteUrl ? satelliteUrl : process.env.REACT_APP_ENGINE_HOST_URL;
	const requestUrl = `${hostUrl + memberUrls.member}/${memberId}`;

	return axios({
		method: 'put',
		url: requestUrl,
		data: {
			update: {
				language,
			},
		},
	});
}

/**
 *
 * @param {string} memberId = member id of logged in member
 * @param {string} profileData = profile data to be added to new member (name, surname, email, phoneNumber)
 * @returns The route request will set the profile data for the new member
 */
export async function updateMemberWithProfileData(memberId: string, profileData: Profile) {
	const satelliteUrl = window.localStorage.getItem('satelliteUrl');
	const hostUrl = satelliteUrl ? satelliteUrl : process.env.REACT_APP_ENGINE_HOST_URL;
	const requestUrl = `${hostUrl + memberUrls.member}/${memberId}`;
	return axios({
		method: 'put',
		url: requestUrl,
		data: {
			update: {
				name: {
					first: profileData?.name,
					last: profileData?.surname,
				},
				phone: profileData?.phoneNumber,
			},
		},
	});
}

/**
 * @returns The route request will logout the member
 */
export async function logoutMember() {
	const satelliteUrl = window.localStorage.getItem('satelliteUrl');
	const hostUrl = satelliteUrl ? satelliteUrl : process.env.REACT_APP_ENGINE_HOST_URL;
	const requestUrl = `${hostUrl + memberUrls.auth + memberUrls.logout}`;
	return axios({
		method: 'delete',
		url: requestUrl,
	});
}
