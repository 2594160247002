import {LargeTitle, makeStyles, Subtitle1} from '@fluentui/react-components';
import {useTranslation} from 'react-i18next';
import React, {useEffect, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {ChevronLeft20Regular} from '@fluentui/react-icons';
import {CommonButton} from '../../components/button/CommonButton';
import {ROUTES} from '../../constants/routes';
import {generateOTP, validateOTP} from '../../api/member';
import OtpInput from './OTPInput';
import {ResendTimer} from './ResendTimer';
import {customTokens} from '../../utils/createTheme';

const OTPInputFocusStyle = {
	border: '2px solid #5236AB',
	outline: 'none',
};

const OTPInputErrorStyle = {
	border: '2px solid #E31937',
	outline: 'none',
};

const OTPInputContainerStyle = {
	justifyContent: 'center',
};

const useInfoTextStyles = makeStyles({
	root: {
		color: customTokens.colorGrey97,
		fontWeight: 'normal',
	},
});
const reminderTimeout = 5000; // value is in ms. After X seconds trigger a reminder for the user that the otp might be in the spam folder
export const OTP: React.FunctionComponent = () => {
	const {t} = useTranslation();
	const [{otp, error, reminder}, setState] = useState({otp: 0, error: false, reminder: false});
	const location = useLocation();
	const email = location?.state?.email;
	const organizationId = location?.state?.organizationId;
	const navigate = useNavigate();
	const infoTextClasses = useInfoTextStyles();

	async function triggerOTPValidation() {
		try {
			const otpValidation = await validateOTP(email, otp);
			window.localStorage.setItem('memberId', otpValidation.data.memberDoc?.id);
			if (otpValidation.data.firstSignIn) {
				navigate(ROUTES.PROFILE_COMPLETION, {state: otpValidation.data});
			} else {
				navigate(ROUTES.DEFAULT);
			}
		} catch (error) {
			setState((currentState) => ({...currentState, error: true}));
			console.log(error);
		}
	}

	async function sendOTPCode() {
		try {
			await generateOTP(email, organizationId);
			return;
		} catch (error) {
			console.log(error);
		}
	}

	function triggerReminder() {
		//if user didnt add any otp code remind them they can check the spam folder
		if (otp === 0) {
			setState((currentState) => ({...currentState, reminder: true}));
		}
	}

	function otpChangeHandler(code: number) {
		setState((currentState) => (reminder ? {...currentState, otp: code, reminder: false} : {...currentState, otp: code}));
	}
	useEffect(() => {
		if (!email || !organizationId) {
			navigate(ROUTES.LOGIN);
		}
		sendOTPCode();
		setTimeout(() => {
			triggerReminder();
		}, reminderTimeout);
	}, []);
	return (
		<React.StrictMode>
			<div className="otp-page">
				<div className="otp-page-back">
					<div className="back-button">
						<CommonButton
							buttonId="otp-back-button"
							icon={<ChevronLeft20Regular />}
							appearance="back"
							onClickHandler={() => navigate(ROUTES.LOGIN)}
						/>
					</div>
				</div>
				<div className="otp-page-modal">
					<LargeTitle as="h1">{t('login.otp.codeVerification')}</LargeTitle>
					<Subtitle1 className={infoTextClasses.root} as="h2">
						{t('login.otp.otpInfo')} <span style={{fontWeight: 'bold'}}>{email}</span>
					</Subtitle1>
					<OtpInput
						value={otp}
						onChange={otpChangeHandler}
						separator={<div className="otp-separator"></div>}
						isInputNum={true}
						shouldAutoFocus={true}
						numInputs={6}
						inputStyle={'verify-input-style'}
						focusStyle={OTPInputFocusStyle}
						containerStyle={OTPInputContainerStyle}
						hasErrored={error}
						errorStyle={OTPInputErrorStyle}
						errorMessage={error && t('errors.loginErrors.otpCode')}
						reminder={reminder && t('login.otp.reminder')}
						className={'single-otp-input-wrapper'}
					/>
					<CommonButton
						size={'large'}
						appearance="primary"
						onClickHandler={triggerOTPValidation}
						buttonId="verify-otp-button"
						text={t('login.otp.verifyCode')}
						className="verify-otp-button"
					/>
					<ResendTimer
						resendCallback={() => {
							setState((currentState) => ({...currentState, error: false, reminder: false}));
							sendOTPCode();
						}}
						infoTextClass={infoTextClasses.root}
					/>
				</div>
			</div>
		</React.StrictMode>
	);
};
