import React, {useContext, useEffect} from 'react';
import {Spinner, Subtitle1, Text, Title1, Title2, Title3} from '@fluentui/react-components';
import {useTranslation} from 'react-i18next';
import {DateTime} from 'luxon';

import {BookingCard} from '../../components/cards/BookingCard';
import '../../styles/app.scss';
import '../../styles/grid.scss';
import '../../styles/pages/dashboard.scss';
import {CommonButton} from '../../components/button/CommonButton';
import {DashboardContext} from '../app/contexts/DashboardContext';
import {ModalContext, ModalView} from '../app/contexts/ModalContext';
import {CurrentUserContext} from '../app/contexts/CurrentUserContext';

export const Dashboard: React.FunctionComponent = () => {
	const {t} = useTranslation();
	const {enableModal} = useContext(ModalContext);
	const {loadingDashboard, selectedOffice, loadBookings, bookings} = React.useContext(DashboardContext);
	const {currentUser} = React.useContext(CurrentUserContext);
	const hero = `${process.env.PUBLIC_URL}/images/DJ_hero_illustration.svg`;
	const no_bookings = `${process.env.PUBLIC_URL}/images/no_bookings_illustration.svg`;
	const [filteredBookings, setFilteredBookings] = React.useState<Array<any>>([]);
	const [pastBookings, setPastBookings] = React.useState<Array<any>>([]);

	function filterBookings() {
		// filters bookings based on selected office
		const localBookings = bookings?.filter((booking: any) => booking?.office === selectedOffice?.id);
		// booking time ended, user checked out, user cancelled; reverse to have most recent past booking first
		const pastBookings = localBookings
			?.filter((booking: any) => {
				return DateTime.fromISO(booking?.end?.time) < DateTime.now() || DateTime.fromISO(booking?.checkOut?.time) < DateTime.now();
			})
			.reverse();
		// filter out past bookings to get other bookings
		const filteredBookings = localBookings.filter((booking: any) => pastBookings.indexOf(booking) === -1);
		setPastBookings(pastBookings);
		setFilteredBookings(filteredBookings);
	}

	function startBooking() {
		enableModal(true, ModalView.NewBooking, undefined);
	}
	useEffect(() => {
		loadBookings();
	}, []);

	//second useEffect is to filter the bookings based on the selected office. The selected office data takes more time to load then loading next bookings
	// TOOD: improve the updated of selectedOffice.
	useEffect(() => {
		if (bookings?.length > 0) {
			filterBookings();
		}
	}, [selectedOffice, bookings]);
	return (
		<main className="content-wrap">
			<section className="hero">
				<div className="hero-background">
					<img src={hero} alt="" className="hero-illustration" />
					<div className="hero-ellipse" />
					<div className="hero-title">
						<Title1 as="h1">
							{t('dashboard.hi')} {currentUser?.name?.first && <span>{currentUser?.name.first}</span>},
							<br />
						</Title1>
						<Subtitle1 as="span">{t('dashboard.greatDay')}</Subtitle1>
					</div>
					{!loadingDashboard && (
						<CommonButton
							buttonId="start-booking-button"
							className="hero-button"
							onClickHandler={startBooking}
							appearance={'primary'}
							text={t('dashboard.startBooking')}
						/>
					)}
				</div>
			</section>
			<section className="dashboard grid">
				{loadingDashboard ? (
					<div className="dashboard-loading">
						<Spinner size="huge" label={`${t('common.loading')}...`} />
					</div>
				) : (
					<>
						{filteredBookings.length === 0 ? (
							<div className="dashboard-empty">
								<div className="dashboard-empty-illustration-holder">
									<img src={no_bookings} alt="" className="dashboard-empty-illustration" />
								</div>
								<div className="dashboard-empty-textbox">
									<Title2 as="h2">
										{t('dashboard.noBookings')}
										<br />
									</Title2>
									<Text style={{marginBottom: '12px'}}>{t('dashboard.noBookingsInfo')}</Text>
									<CommonButton
										onClickHandler={startBooking}
										appearance="outline"
										text={t('dashboard.startBooking')}
										buttonId="second-start-booking-button"
										className="second-start-booking-button"
									/>
								</div>
							</div>
						) : (
							<>
								{/* <MessageBar
									className="dashboard-message"
									isMultiline={false}
									messageBarType={MessageBarType.warning}
									actions={<Button appearance="outline" icon={<ArrowRight24Regular />} shape="circular" aria-label="Continue" />}
								>
									<Text>
										<b>{t('dashboard.beforeCheckin')}</b>
										<br />
										{t('dashboard.confirmQuestions')}
									</Text>
								</MessageBar> */}
								<Title3 as="h3" className="dashboard-h3">
									{t('common.myBookings')}
								</Title3>
								<ul className="dashboard-bookings">
									{filteredBookings.map((booking: any, index: number) => (
										<li key={index} className="dashboard-bookings-item">
											<BookingCard booking={booking} />
										</li>
									))}
								</ul>
							</>
						)}
						{pastBookings.length > 0 && (
							<>
								<Title3 as="h3" className="dashboard-h3">
									{t('common.pastBookings')}
								</Title3>
								<ul className="dashboard-bookings">
									{pastBookings.map((booking: any, index: number) => (
										<li key={index} className="dashboard-bookings-item">
											<BookingCard booking={booking} isPast />
										</li>
									))}
								</ul>
							</>
						)}
					</>
				)}
			</section>
		</main>
	);
};
