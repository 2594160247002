/**
 *
 * @param teakFeatures = list with teak feature ids
 * @param officeTeakFeatures = list of teakfeatures assigned to the selected office
 * @returns the teak features names for provided teak feature id list
 */

export function getFeatureNamesFromIds(teakFeatures: Array<string>, officeTeakFeatures: Array<any>) {
	const result: Array<string> = [];
	teakFeatures?.forEach((teakFeature: string, index: number) => {
		const feature = officeTeakFeatures.find((feature: any) => feature?._id === teakFeature);
		result.push(feature.name);
	});
	return result;
}
