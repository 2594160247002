import React from 'react';
import {DateTime} from 'luxon';

interface FormattedDateTimeProps {
	dateTime: DateTime;
	dateTimeFormat?: any;
}

export const FormattedDateTime: React.FunctionComponent<FormattedDateTimeProps> = (props: FormattedDateTimeProps) => {
	const {dateTime, dateTimeFormat} = props;
	const formattedDateTime = `${dateTime.toLocaleString(dateTimeFormat ? dateTimeFormat : DateTime.DATE_SHORT)} | ${dateTime.toLocaleString(
		DateTime.TIME_SIMPLE,
	)}`;
	return <React.Fragment>{formattedDateTime}</React.Fragment>;
};
