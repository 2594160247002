import axios from 'axios';

axios.defaults.withCredentials = true;

const teakTypeUrls = {
	teakType: '/biro/teak/type',
	findMany: '/findMany',
};

/**
 * @param {string} office = office id for which to get teak types
 * @returns The route request will get all the teak types for this office.
 */
export function getTeakTypes(office: string) {
	const spacestationUrl: string = process.env.REACT_APP_ENGINE_HOST_URL as string;
	const requestUrl = `${spacestationUrl + teakTypeUrls.teakType + teakTypeUrls.findMany}`;

	return axios({
		method: 'post',
		url: requestUrl,
		data: {
			filters: {
				office,
			},
		},
	});
}
