import React, {createContext, useState} from 'react';
import {ContextProviderProps} from './contextInterface';

interface CurrentUserContextType {
	currentUser: any;
	updateUser: (newValue: any) => void;
}

export const CurrentUserContext = createContext<CurrentUserContextType>({currentUser: null, updateUser: () => console.log('user updated')});

export const CurrentUserContextProvider: React.FunctionComponent<ContextProviderProps> = (props: ContextProviderProps) => {
	const {children} = props;
	const [currentUser, setCurrentUser] = useState(null);

	return (
		<CurrentUserContext.Provider value={{currentUser, updateUser: (value: any) => setCurrentUser(value)}}>{children}</CurrentUserContext.Provider>
	);
};
