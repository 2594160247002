import React, {useContext} from 'react';
import {ComboboxProps, Menu, MenuTrigger, MenuList, MenuItem, MenuPopover, Option} from '@fluentui/react-components';
import {Persona} from '@fluentui/react-components';
import {CommonCombobox} from '../inputs/CommonCombobox';
import {getDataForOffice} from '../../utils/getDataForOffice';
import {logoutMember, updateUserOffice} from '../../api/member';
import {useTranslation} from 'react-i18next';
import '../../styles/header.scss';
import {LanguageDrodpdown} from './LanguageDropdown';
import {DashboardContext} from '../../pages/app/contexts/DashboardContext';
import {CurrentUserContext} from '../../pages/app/contexts/CurrentUserContext';
import {AlertContext} from '../../pages/app/contexts/AlertContext';

export const Header: React.FunctionComponent = () => {
	const {t} = useTranslation();
	const {enableAlert} = useContext(AlertContext);
	const {currentUser} = React.useContext(CurrentUserContext);
	const {offices, selectedOffice, updateLoadingDashboard, updateSelectedOffice} = React.useContext(DashboardContext);
	const [matchingOffices, setMatchingOffices] = React.useState<any[]>([]);
	const [officeSelection, setOfficeSelection] = React.useState<{option: string[]; value: string}>({option: [''], value: ''});
	const logo = `${process.env.PUBLIC_URL}/images/DOORJAMES_LOGO.svg`;

	// Backend calls for updating the office selection
	async function updateOfficeSelection(officeId: string) {
		try {
			const selectedOfficeCompleteData = await getDataForOffice(officeId);
			await updateUserOffice(currentUser._id, officeId);
			updateSelectedOffice({
				...selectedOfficeCompleteData,
				...offices.find((office: any) => office?._id === officeId),
				id: officeId,
			});
			updateLoadingDashboard(false);
			enableAlert({show: true, intent: 'success', text: t('success.officeChangedSuccessfully')});
		} catch (error: any) {
			console.log(error);
			enableAlert({
				show: true,
				intent: 'error',
				text: 'errors.genericError',
			});
			updateLoadingDashboard(false);
		}
	}

	// filter function for the ComboBox options
	const filter = (value: string) => {
		const matches = offices.filter((office) => office?.name?.toLowerCase().indexOf(value.toLowerCase()) === 0);
		setMatchingOffices(matches);
	};

	// set the proper selection and filter in the ComboBox once the offices have loaded from context
	React.useEffect(() => {
		const value = offices.find((office: any) => office._id === selectedOffice?.id)?.name ?? '';
		filter('');
		setOfficeSelection({option: [selectedOffice?.id ?? ''], value: value});
	}, [selectedOffice]);

	// on writing into the ComboBox
	const onOfficeChange: ComboboxProps['onChange'] = (event) => {
		const value = event.target.value;
		filter(value.trim());
		setOfficeSelection({...officeSelection, value: value});
	};

	// on selecting an option from the ComboBox
	const onOptionSelect: Partial<ComboboxProps>['onOptionSelect'] = (ev, data) => {
		const officeId = data.optionValue;
		const officeName = data.optionText ?? '';
		filter('');
		setOfficeSelection({option: data.selectedOptions, value: officeName});
		if (officeId && officeId !== selectedOffice?.id) {
			updateLoadingDashboard(true);
			updateOfficeSelection(officeId);
		}
	};

	const logoutUser = async () => {
		try {
			await logoutMember();
			window.localStorage.clear();
			return window.location.reload();
		} catch (error: any) {
			console.log(error);
			if (error?.response?.data?.errorName === 'sessionNotFound') {
				window.localStorage.clear();
				return window.location.reload();
			} else {
				enableAlert({
					show: true,
					intent: 'error',
					text: 'errors.logoutError',
				});
			}
		}
	};

	return (
		<header className="main-header">
			<img src={logo} alt="Door James" className="logo-doorjames" />
			<nav className="main-header-nav">
				<form name={t('header.formChangeOffice') ?? 'Form for changing the office location'}>
					<CommonCombobox
						value={officeSelection.value}
						selectedOptions={officeSelection.option}
						onChange={onOfficeChange}
						onOptionSelect={onOptionSelect}
						placeholder={t('header.selectOfficePlaceholder') ?? 'Select an office location'}
					>
						{matchingOffices.map((office) => (
							<Option key={office._id} value={office._id}>
								{office.name}
							</Option>
						))}
					</CommonCombobox>
				</form>
				<LanguageDrodpdown />
				<div className="main-header-nav-part" />
				<Menu>
					<MenuTrigger disableButtonEnhancement>
						<Persona className="main-header-nav-persona" />
					</MenuTrigger>

					<MenuPopover>
						<MenuList>
							{/* <MenuItem>{t('menuItem.profile')}</MenuItem> */}
							{/* <MenuItem>{t('menuItem.settings')}</MenuItem>
							<MenuItem>{t('menuItem.covidStatus')}</MenuItem>
							<MenuItem>{t('menuItem.reportProblem')}</MenuItem> */}
							<MenuItem onClick={logoutUser}>{t('menuItem.logout')}</MenuItem>
						</MenuList>
					</MenuPopover>
				</Menu>
			</nav>
		</header>
	);
};
