import axios from 'axios';

const teakEntityUrls = {
	teakEntity: '/biro/teak/entity',
	findMany: '/findMany',
	book: '/book',
};

/**
 * @param {string} office = office id for which to get available teak entities
 * @param {string} area = area id in which to search for teak entities
 * @param {string} teakType = teak type for which to get available teakEntities ( teakType = desk, room, parking space)
 * @param {string} start = start time of the requested range
 * @param {string} end = end time of the requested range
 * @returns The route request will get all available teak entities inside an area
 */
export function getAvailableTeakEntitiesInOneArea(office: string, area: string, teakType: string, start: string, end: string) {
	const spacestationUrl: string = process.env.REACT_APP_ENGINE_HOST_URL as string;
	const requestUrl = `${spacestationUrl + teakEntityUrls.teakEntity + teakEntityUrls.findMany}`;

	return axios({
		method: 'post',
		url: requestUrl,
		data: {
			filters: {
				office,
				area,
				teakType,
			},
			includeTeakBookingInRange: {
				start,
				end,
			},
		},
	});
}

/**
 * @param {string} teakEntity = teak Entity id to be booked
 * @param {string} start = start time of the new booking
 * @param {string} end = end time of the new booking
 * @param {string} meetingName = meeting Name required for bookings made for meeting rooms - currently be default is 'Meeting name TeakEntity'
 * @returns The route request will make a new booking for the selected time range and teakEntity.
 */
export async function bookTeakEntity(teakEntity: string, start: string, end: string, meetingName?: string) {
	const spacestationUrl: string = process.env.REACT_APP_ENGINE_HOST_URL as string;
	const requestUrl = `${spacestationUrl + teakEntityUrls.teakEntity}/${teakEntity + teakEntityUrls.book}`;

	return axios({
		method: 'post',
		url: requestUrl,
		data: {
			start,
			end,
			meetingName,
			checkInNow: false,
		},
	});
}
