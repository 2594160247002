import {Body1, Body1Strong, Button, Subtitle1, Title3} from '@fluentui/react-components';
import React, {useContext} from 'react';
import {DateTime} from 'luxon';
import {useTranslation} from 'react-i18next';

import {getFeatureNamesFromIds} from '../../../utils/getFeatureNamesFromIds';
import {getAreaNameFromId} from '../../../utils/getAreaNameFromId';
import {teakTypeNamesMap} from '../../../constants/teakTypeNamesMap';
import {customTokens} from '../../../utils/createTheme';
import {CommonButton} from '../../../components/button/CommonButton';
import {DateTimePicker} from '../../../components/dateTimePicker/DateTimePicker';
import {editTeakBooking} from '../../../api/teakBooking';
import {DashboardContext} from '../../app/contexts/DashboardContext';
import {ModalContext} from '../../app/contexts/ModalContext';
import {AlertContext} from '../../app/contexts/AlertContext';
export const EditBookingModal: React.FunctionComponent = () => {
	const {t} = useTranslation();
	const {selectedOffice, loadBookings} = useContext(DashboardContext);
	const {enableModal, modalOptions} = useContext(ModalContext);
	const {enableAlert} = useContext(AlertContext);
	const bookingData = modalOptions.modalData;
	const [startTime, setStartTime] = React.useState(DateTime.fromISO(bookingData?.start?.time));
	const [endTime, setEndTime] = React.useState(DateTime.fromISO(bookingData?.end?.time));
	const [errors, setErrors] = React.useState<{timeError: boolean; dateError: boolean; errorMessage: string | undefined | null}>({
		timeError: false,
		dateError: false,
		errorMessage: undefined,
	});
	const teakTypeName: string | undefined = teakTypeNamesMap.get(bookingData?.teakEntity?.__t);
	const teakTypeNameTranslated: string = teakTypeName ? t(teakTypeName) : t('teakTypes.generic');

	function clearErrors() {
		setErrors({timeError: false, dateError: false, errorMessage: undefined});
	}

	function closeModal() {
		enableModal(false, undefined, undefined);
	}

	async function editBookingHandler() {
		try {
			const startTimeUTC = startTime.toUTC().toISO();
			const endTimeUTC = endTime.toUTC().toISO();
			await editTeakBooking(bookingData._id, startTimeUTC, endTimeUTC);
			enableModal(false, undefined, undefined);
			enableAlert({show: true, intent: 'success', text: t('success.bookingEdited')});
			loadBookings();
		} catch (error: any) {
			const errorMessageKey = error?.response?.data?.name ? `errors.bookingErrors.${error.response.data.name}` : 'genericError';
			enableAlert({
				show: true,
				intent: 'error',
				text: errorMessageKey,
			});
			setErrors({dateError: true, timeError: true, errorMessage: t(errorMessageKey)});
		}
	}

	function changeStartTime(newValue: DateTime) {
		if (newValue > endTime) {
			setErrors({dateError: true, timeError: true, errorMessage: t('errors.bookingErrors.endTimeBeforeStart')});
		} else if (errors.timeError) {
			clearErrors();
		}
		setStartTime(newValue);
	}
	function changeEndTime(newValue: DateTime) {
		if (newValue < startTime) {
			setErrors({dateError: true, timeError: true, errorMessage: t('errors.bookingErrors.endTimeBeforeStart')});
		} else if (errors.timeError) {
			clearErrors();
		}
		setEndTime(newValue);
	}
	return (
		<div className="modal-wrapper booking-details-modal">
			<div className="modal-header">
				<Title3 as="h1">{t('common.editBooking')}</Title3>
				<Button style={{minWidth: '0', color: customTokens.colorBrandBackground, border: 'none'}} onClick={closeModal}>
					X
				</Button>
			</div>
			<div className="modal-content">
				<Subtitle1 as="h2" id={'booking-details'}>
					{t('common.bookingDetails')}
				</Subtitle1>
				<div className="booking-detail-info">
					<Body1Strong as="p" className="value-label">
						{teakTypeNameTranslated}
					</Body1Strong>
					<Body1 as="p">{bookingData?.teakEntity?.name}</Body1>
				</div>
				<div className="booking-detail-info">
					<Body1Strong as="p" className="value-label">
						{t('common.location')}
					</Body1Strong>
					<Body1 as="p">{getAreaNameFromId(bookingData?.teakEntity?.area, selectedOffice?.areas)}</Body1>
				</div>
				<div className="booking-detail-info">
					<Body1Strong as="p" className="value-label">
						{t('common.office')}
					</Body1Strong>
					<Body1 as="p">{selectedOffice?.name}</Body1>
				</div>
				{bookingData?.teakEntity?.teakFeatures?.length > 0 && (
					<div className="booking-detail-info">
						<Body1Strong as="p" className="value-label">
							{t('common.features')}
						</Body1Strong>
						<div className="entity-features-wrapper ">
							{getFeatureNamesFromIds(bookingData?.teakEntity?.teakFeatures, selectedOffice?.teakFeatures)?.map(
								(featureName, index) => (
									<div key={index} className="feature-label">
										<Body1 as="p">{featureName}</Body1>
									</div>
								),
							)}
						</div>
					</div>
				)}
				<div className="date-time-wrapper">
					<Subtitle1 as="h2" id={'date-time-label'}>
						{t('common.dateTime')}*
					</Subtitle1>
					<div className="date-time-wrapper-row">
						<DateTimePicker
							dateTime={startTime}
							onChange={changeStartTime}
							dateLabel={t('common.fromDate')}
							timeLabel={t('common.fromTime')}
							timeError={errors.timeError}
							dateError={errors.dateError}
							// timezone={'America/Toronto'}
						/>
					</div>
					<div className="date-time-wrapper-row">
						<DateTimePicker
							dateTime={endTime}
							// timezone={'America/Toronto'}
							onChange={changeEndTime}
							dateLabel={t('common.toDate')}
							timeLabel={t('common.toTime')}
							timeError={errors.timeError}
							dateError={errors.dateError}
						/>
					</div>
				</div>
				{errors.errorMessage && (
					<Body1 className="date-time-error" style={{color: customTokens.colorRed}} as={'p'}>
						{errors.errorMessage}
					</Body1>
				)}
			</div>
			<div className="modal-footer">
				<CommonButton buttonId="edit-booking" appearance="primary" onClickHandler={editBookingHandler} text={t('common.confirmChanges')} />
			</div>
		</div>
	);
};
