import {Dropdown, IDropdownOption} from '@fluentui/react';
import {Radio, RadioGroup, Subtitle1, tokens, Spinner, Caption1, Body1, Button, makeStyles} from '@fluentui/react-components';
import React, {useContext} from 'react';
import {DateTime} from 'luxon';
import {makeResetStyles} from '@griffel/react';
import {useTranslation} from 'react-i18next';

import {DateTimePicker} from '../../../components/dateTimePicker/DateTimePicker';
import {CommonButton} from '../../../components/button/CommonButton';
import {customTokens} from '../../../utils/createTheme';
import {CommonInput} from '../../../components/inputs/CommonInput';
import {Dismiss12Regular} from '@fluentui/react-icons';
import {useId} from '@fluentui/react-hooks';
import {DashboardContext} from '../../app/contexts/DashboardContext';
import {roundTimeQuarterHour} from '../../../functions/roundTimeToNextQuarter';

const useDropdownStyles = makeResetStyles({
	'& div': {
		maxWidth: '700px', //same max width as the max widht of the modal content
	},
	'& div::after': {
		borderRadius: '12px',
	},
	'& span': {
		borderRadius: '12px',
		textOverflow: 'ellipsis',
		'& i': {
			color: tokens.colorBrandBackground,
			fontWeight: 'bold',
		},
	},
});
const useStyles = makeStyles({
	featuresList: {
		listStyleType: 'none',
		marginBottom: tokens.spacingVerticalXXS,
		marginTop: 0,
		paddingLeft: 0,
		display: 'flex',
		gridGap: tokens.spacingHorizontalXXS,
	},
});
interface NewBookingFormProps {
	data?: any;
	loadingResults?: boolean;
	searchResults: (teakType: string, startTime: DateTime, endTime: DateTime, area: string, teakFeatures: Array<string>, meetingName: string) => void;
}
export const NewBookingForm: React.FunctionComponent<NewBookingFormProps> = (props: NewBookingFormProps) => {
	const {t} = useTranslation();
	const comboId = useId('combo-features');
	const selectedListId = `${comboId}-selection`;
	const selectedListRef = React.useRef<HTMLUListElement>(null);
	const featureDropdownRef = React.useRef<HTMLDivElement>(null);
	const {searchResults, data, loadingResults} = props;
	const dropdownStyles = useDropdownStyles();
	const {selectedOffice} = useContext(DashboardContext);
	const timezone = selectedOffice?.timezone;
	const mainStyles = useStyles();
	const [meetingName, setMeetingName] = React.useState<string>('');
	const [teakType, setTeakType] = React.useState<any>(data?.teakType);
	const [area, setArea] = React.useState<string | number | undefined>(data?.area ? data.area?._id : 'all_areas');
	/* eslint-disable no-mixed-spaces-and-tabs */
	const [startTime, setStartTime] = React.useState(
		data?.startTime
			? data.startTime
			: DateTime.fromISO(roundTimeQuarterHour(DateTime.now().setZone(timezone).toISO() ?? new Date().toISOString())),
	);
	const [endTime, setEndTime] = React.useState(
		data?.endTime
			? data.endTime
			: DateTime.fromISO(roundTimeQuarterHour(DateTime.now().setZone(timezone).plus({hours: 8}).toISO() ?? new Date().toISOString())),
	);
	/* eslint-disable no-mixed-spaces-and-tabs */
	const [teakFeaturesList, setTeakFeaturesList] = React.useState<Array<string>>([]);
	const [errors, setErrors] = React.useState<{timeError: boolean; dateError: boolean; errorMessage: string | undefined | null}>({
		timeError: false,
		dateError: false,
		errorMessage: undefined,
	});

	function clearErrors() {
		setErrors({timeError: false, dateError: false, errorMessage: undefined});
	}

	function changeStartTime(newTime: DateTime) {
		if (newTime.ordinal > endTime.ordinal) {
			setStartTime(newTime);
			setEndTime(
				DateTime.fromObject({
					year: newTime.year,
					month: newTime.month,
					day: newTime.day,
					hour: endTime?.hour,
					minute: endTime?.minute,
				}),
			);
		} else {
			if (newTime > endTime) {
				setErrors({dateError: true, timeError: true, errorMessage: t('errors.bookingErrors.endTimeBeforeStart')});
			} else if (errors.timeError) {
				clearErrors();
			}
			setStartTime(newTime);
		}
	}
	function changeEndTime(newTime: DateTime) {
		if (newTime < startTime) {
			setErrors({dateError: true, timeError: true, errorMessage: t('errors.bookingErrors.endTimeBeforeStart')});
		} else if (errors.timeError) {
			clearErrors();
		}
		setEndTime(newTime);
	}

	function getDropdownFeaturesOptions(selectedTeakTypeId: string) {
		const options: IDropdownOption[] = [];
		if (!selectedOffice?.teakFeatures) {
			return options;
		}

		selectedOffice?.teakFeatures.forEach((teakFeature: any) => {
			if (teakFeature?.teakType === selectedTeakTypeId) {
				options.push({key: teakFeature._id, text: teakFeature.name});
			}
		});
		return options;
	}

	function getDropdownAreaOption() {
		const options: IDropdownOption[] = [{key: 'all_areas', text: t('common.allAreas')}];
		if (!selectedOffice?.areasTree?.areas) {
			return options;
		}
		selectedOffice?.areasTree?.areas?.forEach((area: any) => {
			options.push({key: area._id, text: area.name});
		});
		return options;
	}

	function searchResultsHandler() {
		let selectedArea = area;
		if (typeof selectedArea === 'number') selectedArea = selectedArea.toString();
		if (teakType && startTime && endTime && selectedArea) {
			searchResults(teakType, startTime, endTime, selectedArea, teakFeaturesList, meetingName);
		}
	}

	function teakFeatureSelection(selectedOption: any) {
		const currentTeakFeatures = teakFeaturesList;
		const isTeakFeatureInList = currentTeakFeatures.find((teakFeature: any) => teakFeature.key === selectedOption.key);
		if (selectedOption.selected) {
			if (!isTeakFeatureInList) {
				setTeakFeaturesList((currentList: any) => [...currentList, selectedOption]);
			}
		} else {
			if (isTeakFeatureInList) {
				const filteredTeakFeatureList = currentTeakFeatures.filter((teakFeature: any) => teakFeature.key !== selectedOption.key);
				setTeakFeaturesList(filteredTeakFeatureList);
			}
		}
	}
	function changeTeakType(newTypeObject: any) {
		if (newTypeObject !== teakType) {
			setTeakType(newTypeObject);
			setMeetingName('');
		}
	}

	function onFeatureTagClick(option: any, index: number) {
		// remove selected option
		setTeakFeaturesList(teakFeaturesList.filter((teakFeature: any) => teakFeature.key !== option.key));

		// focus previous or next option, defaulting to focusing back to the combo input
		const indexToFocus = index === 0 ? 1 : index - 1;
		const optionToFocus = selectedListRef.current?.querySelector(`#${comboId}-remove-${indexToFocus}`);
		if (optionToFocus) {
			(optionToFocus as HTMLButtonElement).focus();
		} else {
			featureDropdownRef.current?.focus();
		}
	}
	return (
		<React.Fragment>
			<div className="modal-content">
				<fieldset id="new-booking-form">
					<form
						name={t('newBookingModal.formName') ?? 'New booking form, please select asset type, booking times, features and the location'}
					>
						<div className={'asset-type-radio'}>
							<Subtitle1 as="h2" id={'asset-type-label'}>
								{t('newBookingModal.assetType')}*
							</Subtitle1>
							<RadioGroup
								layout="horizontal"
								aria-labelledby={t('newBookingModal.ariaLabelRadioGroup') ?? 'Radio group for selecting an asset type'}
							>
								{selectedOffice?.teakTypes?.workplace && (
									<Radio
										aria-label={t('newBookingModal.ariaLabelDeskSelect') ?? 'Select desk booking'}
										checked={selectedOffice?.teakTypes?.workplace?._id === teakType?._id}
										onClick={() => changeTeakType(selectedOffice?.teakTypes?.workplace)}
										value="desk"
										label={t('teakTypes.desk')}
									/>
								)}
								{selectedOffice?.teakTypes?.meetingRoom && (
									<Radio
										aria-label={t('newBookingModal.ariaLabelRoomSelect') ?? 'Select room booking'}
										checked={selectedOffice?.teakTypes?.meetingRoom?._id === teakType?._id}
										onClick={() => changeTeakType(selectedOffice?.teakTypes?.meetingRoom)}
										value="room"
										label={t('teakTypes.room')}
									/>
								)}
								{selectedOffice?.teakTypes?.parkingSpace && (
									<Radio
										aria-label={t('newBookingModal.ariaLabelParkingSpaceSelect') ?? 'Select parking space booking'}
										checked={selectedOffice?.teakTypes?.parkingSpace?._id === teakType?._id}
										onClick={() => changeTeakType(selectedOffice?.teakTypes?.parkingSpace)}
										value="parking"
										label={t('teakTypes.parkingSpace')}
									/>
								)}
							</RadioGroup>
						</div>
						{selectedOffice?.teakTypes?.meetingRoom?._id === teakType?._id && (
							<div className="meeting-name-wrapper">
								<Subtitle1 as="h2" id={'meeting-name-label'}>
									{t('common.meetingName')}
								</Subtitle1>
								<CommonInput
									placeholder={t('newBookingModal.meetingNamePlaceholder')}
									inputId="meeting-name"
									ariaLabel={t('newBookingModal.ariaLabelMeetingName')}
									value={meetingName}
									onChangeHandler={(e) => setMeetingName(e.target.value)}
									noCustomClass={true}
									maxLength={50}
								/>
							</div>
						)}
						<div className="date-time-wrapper">
							<Subtitle1 as="h2" id={'date-time-label'}>
								{t('common.dateTime')}*
							</Subtitle1>
							<div className="date-time-wrapper-row">
								<DateTimePicker
									dateTime={startTime}
									onChange={changeStartTime}
									dateLabel={t('common.fromDate')}
									timeLabel={t('common.fromTime')}
									timeError={errors.timeError}
									dateError={errors.dateError}
									timezone={timezone}
								/>
							</div>
							<div className="date-time-wrapper-row">
								<DateTimePicker
									dateTime={endTime}
									timezone={timezone}
									onChange={changeEndTime}
									dateLabel={t('common.toDate')}
									timeLabel={t('common.toTime')}
									timeError={errors.timeError}
									dateError={errors.dateError}
								/>
							</div>
							{errors.errorMessage && (
								<Body1 className="date-time-error" style={{color: customTokens.colorRed}} as={'p'}>
									{errors.errorMessage}
								</Body1>
							)}
						</div>
						<div className="features-wrapper">
							<Subtitle1 as="h2" id={'features-label'}>
								{t('common.features')}
							</Subtitle1>
							{teakFeaturesList.length > 0 ? (
								<ul id={selectedListId} className={mainStyles.featuresList} ref={selectedListRef}>
									{teakFeaturesList.map((option: any, i) => {
										return (
											<li key={option.key}>
												<Button
													size="small"
													shape="circular"
													appearance="primary"
													icon={<Dismiss12Regular />}
													iconPosition="after"
													onClick={() => onFeatureTagClick(option, i)}
													id={`${comboId}-remove-${i}`}
													aria-labelledby={`${comboId}-remove ${comboId}-remove-${i}`}
												>
													{option.text}
												</Button>
											</li>
										);
									})}
								</ul>
							) : null}
							<Dropdown
								className={dropdownStyles}
								ref={featureDropdownRef}
								aria-label={t('newBookingModal.ariaLabelFeatures') ?? 'Dropdown for selecting a set of features for your asseet'}
								placeholder={t('newBookingModal.featuresPlaceholder') ?? 'Select features'}
								multiSelect
								selectedKeys={teakFeaturesList.map((feature: any) => feature.key)}
								options={getDropdownFeaturesOptions(teakType?._id)}
								onChange={(e, option) => teakFeatureSelection(option)}
							/>
						</div>
						<div className="location-wrapper">
							<Subtitle1 as="h2" id={'location-label'}>
								{t('common.location')}
							</Subtitle1>
							<Dropdown
								className={dropdownStyles}
								aria-label={
									t('newBookingModal.locationAriaLabel') ?? 'Dropdown for selecting a location where to search for an asset'
								}
								placeholder={t('newBookingModal.locationPlaceholder') ?? 'Select location'}
								options={getDropdownAreaOption()}
								onChange={(e, option) => setArea(option?.key)}
								selectedKey={area}
							/>
						</div>
						<Caption1 as={'p'} style={{margin: '30px 0 0 0', color: customTokens.colorGrey97}}>
							* {t('newBookingModal.marksMandatory')}
						</Caption1>
					</form>
				</fieldset>
			</div>
			<div className="modal-footer">
				{loadingResults ? (
					<Spinner size="extra-small" label={t('common.loadingResults')} />
				) : (
					<CommonButton
						buttonId="search-results-form"
						appearance="primary"
						disabled={!teakType || !startTime || !endTime || errors.errorMessage !== undefined}
						onClickHandler={searchResultsHandler}
						text={t('common.showResults')}
					/>
				)}
			</div>
		</React.Fragment>
	);
};
