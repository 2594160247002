import {Subtitle1, Title1} from '@fluentui/react-components';
import {useTranslation} from 'react-i18next';
import React, {useContext, useEffect} from 'react';
import {useNavigate, useLocation} from 'react-router-dom';
import {CommonButton} from '../../components/button/CommonButton';
import {AlertContext} from '../app/contexts/AlertContext';
import {CommonInput} from '../../components/inputs/CommonInput';
import {ROUTES} from '../../constants/routes';
import {updateMemberWithProfileData} from '../../api/member';

const backgroundImage = `${process.env.PUBLIC_URL}/images/login_background.svg`;
const doorjamesLogoUrl = `${process.env.PUBLIC_URL}/images/DOORJAMES_LOGO.svg`;

export interface Profile {
	name?: string;
	surname?: string;
	email?: string;
	phoneNumber?: string;
}
export const ProfileCompletion: React.FunctionComponent = () => {
	const {t} = useTranslation();
	const {enableAlert} = useContext(AlertContext);
	const navigate = useNavigate();
	const location = useLocation();
	const [profile, setProfile] = React.useState<Profile>({email: location?.state?.memberDoc?.email, name: '', surname: '', phoneNumber: ''});
	const [loading, setLoading] = React.useState(false);

	useEffect(() => {
		if (!location.state) {
			navigate(ROUTES.LOGIN);
			return;
		}
	}, []);

	async function confirmSelectionHandler() {
		setLoading(true);
		try {
			await updateMemberWithProfileData(location?.state?.memberDoc?._id, profile);
			navigate(ROUTES.DEFAULT);
		} catch (error) {
			enableAlert({
				show: true,
				intent: 'error',
				text: 'errors.loginErrors.failedProfileCompletion',
			});
			setLoading(false);
		}
	}
	return (
		<React.StrictMode>
			<div className="profile-completion-page intro-page">
				<div className="profile-completion-page-content intro-page-content">
					<div className="profile-completion-page-content-details intro-page-content-details">
						<img className="doorjames-logo" src={doorjamesLogoUrl} alt="Door James" />
						<Title1 as="h1" className="header">
							{' '}
							{t('profileCompletion.profileCompletion')}{' '}
						</Title1>
						<Subtitle1 as="h2" className="header">
							{' '}
							{t('profileCompletion.profileCompletionInfo')}{' '}
						</Subtitle1>
						<form>
							<CommonInput
								placeholder={t('common.namePlaceholder')}
								inputId="profile-completion-name"
								label={`${t('common.name')}*`}
								ariaLabel={t('profileCompletion.ariaLabelName')}
								value={profile?.name}
								onChangeHandler={(e) => setProfile((current) => ({...current, name: e.target.value}))}
								size="large"
							/>
							<CommonInput
								placeholder={t('common.surnamePlaceholder')}
								inputId="profile-completion-surname"
								label={`${t('common.surname')}*`}
								ariaLabel={t('profileCompletion.ariaLabelSurname')}
								value={profile?.surname}
								onChangeHandler={(e) => setProfile((current) => ({...current, surname: e.target.value}))}
								size="large"
							/>
							<CommonInput
								placeholder={t('common.emailPlaceholder')}
								inputId="profile-completion-email-address"
								label={t('common.email')}
								ariaLabel={t('login.ariaLabelEmail')}
								value={profile?.email}
								onChangeHandler={(e) => setProfile((current) => ({...current, email: e.target.value}))}
								size="large"
								disabled={true}
							/>
							<CommonInput
								placeholder={t('common.phoneNumberPlaceholder')}
								inputId="profile-completion-phone-number"
								label={t('common.phoneNumber')}
								ariaLabel={t('profileCompletion.ariaLabelPhoneNumber')}
								value={profile?.phoneNumber}
								onChangeHandler={(e) => setProfile((current) => ({...current, phoneNumber: e.target.value}))}
								size="large"
								type="number"
							/>
						</form>
					</div>

					<CommonButton
						buttonId="search-results-form"
						appearance="primary"
						disabled={!profile || !profile.name || !profile.surname}
						onClickHandler={confirmSelectionHandler}
						loading={loading}
						text={t('common.confirm')}
					/>
				</div>
				<div className="profile-completion-page-image-wrapper intro-page-image-wrapper">
					<img src={backgroundImage} alt="" />
				</div>
			</div>
		</React.StrictMode>
	);
};
