import {BrandVariants, createLightTheme, Theme, themeToTokensObject} from '@fluentui/react-components';

const customBrandRamp: BrandVariants = {
	10: '#100B22',
	20: '#211644',
	30: '#312067',
	40: '#BAAFDD',
	50: '#BAAFDD',
	60: '#422B89',
	70: '#422B89',
	80: '#5236AB',
	90: '#9786CD',
	100: '#9786CD',
	110: '#BAAFDD',
	120: '#BAAFDD',
	130: '#DCD7EE',
	140: '#DCD7EE',
	150: '#EEEBF7',
	160: '#EEEBF7',
};
const themeWithDJColors = createLightTheme(customBrandRamp);

export const customLightTheme: Theme & {
	colorGreen: string;
	colorGreenLight: string;
	colorRedHover: string;
	colorRed: string;
	colorRedLight: string;
	borderRadiusXXLarge: string;
	colorGrey97: string;
} = {
	...themeWithDJColors,
	fontSizeBase100: '0.625rem',
	fontSizeBase200: '0.75rem',
	fontSizeBase300: '0.875rem',
	fontSizeBase400: '1rem',
	fontSizeBase500: '1.25rem',
	fontSizeBase600: '1.5rem',
	fontSizeHero700: '1.75rem',
	fontSizeHero800: '2rem',
	fontSizeHero900: '2.5rem',
	fontSizeHero1000: '4.25rem',
	colorGreen: '#2ac769',
	colorGreenLight: '#d8f6e5',
	colorRed: '#dd052b',
	colorRedHover: '#b10422',
	colorRedLight: '#fbdde1',
	colorGrey97: '#979797',
	borderRadiusXXLarge: '12px',
};
export const customTokens = themeToTokensObject(customLightTheme);
