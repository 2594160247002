import {Button, Title3} from '@fluentui/react-components';
import {useTranslation} from 'react-i18next';
import React, {useContext} from 'react';
import {DateTime} from 'luxon';

import {NewBookingForm} from './NewBookingForm';
import {getAreasWithTheirAvailableEntities} from '../../../api/area';
import {TeakEntityList} from './TeakEntityList';
import {BookingSummaryModal} from './BookingSummaryModal';
import {customTokens} from '../../../utils/createTheme';
import {DashboardContext} from '../../app/contexts/DashboardContext';
import {ModalContext} from '../../app/contexts/ModalContext';

export const BookingModal: React.FunctionComponent = () => {
	const {t} = useTranslation();
	const {enableModal} = useContext(ModalContext);
	const {selectedOffice} = useContext(DashboardContext);
	const [loadingResults, setLoadingResults] = React.useState(false);
	/**
	 * step = step to shown for creating a booking
	 * availableEntities = array of available entities for selected entries
	 * data = data selected by users during booking flow
	 */
	const [bookingData, setBookingData] = React.useState<{step: number; availableEntities?: Array<any>; data?: any}>({
		step: 1,
		data: {
			//preselect the first teaktype that is available -- usually thats the desk
			teakType: selectedOffice.teakTypes?.workplace
				? selectedOffice.teakTypes?.workplace
				: selectedOffice.teakTypes?.meetingRoom
				? selectedOffice.teakTypes?.meetingRoom
				: selectedOffice.teakTypes?.parkingSpace,
		},
	});

	async function searchResults(
		teakType: any,
		startTime: DateTime,
		endTime: DateTime,
		area: string,
		teakFeatures: Array<string>,
		meetingName: string,
	) {
		setLoadingResults(true);
		try {
			const start = startTime.toUTC().toISO();
			const end = endTime.toUTC().toISO();
			const availableTeakEntitiesResponse = await getAreasWithTheirAvailableEntities(selectedOffice.id, teakType._id, start, end);
			const selectedArea =
				area === 'all_areas'
					? {availableEntities: [], children: availableTeakEntitiesResponse.data, _id: 'all_areas'} // made the object look like area with id 'all_area' is a wrapper area that has as children the other areas
					: await availableTeakEntitiesResponse.data.find((areaWithEntities: any) => areaWithEntities._id === area);

			setBookingData({
				step: 2,
				data: {
					start: startTime,
					end: endTime,
					area: selectedArea,
					teakType,
					teakFeatures,
					meetingName,
				},
			});
			setLoadingResults(false);
		} catch (error) {
			console.log(error);
			setLoadingResults(false);
		}
	}

	function confirmEntitySelection(entity: any) {
		setBookingData((currentData) => ({...currentData, step: 3, data: {...currentData.data, selectedEntity: entity}}));
	}

	function getModalContent() {
		switch (bookingData.step) {
			case 1:
				return <NewBookingForm loadingResults={loadingResults} data={bookingData.data} searchResults={searchResults} />;
			case 2:
				return (
					<TeakEntityList
						goBack={() => setBookingData((current) => ({...current, step: 1}))}
						data={bookingData?.data}
						confirmEntitySelection={confirmEntitySelection}
						searchResults={searchResults}
						loadingResults={loadingResults}
					/>
				);
			case 3:
				return <BookingSummaryModal goBack={() => setBookingData((current) => ({...current, step: 2}))} data={bookingData.data} />;
			default:
				return <NewBookingForm loadingResults={loadingResults} data={bookingData.data} searchResults={searchResults} />;
		}
	}
	return (
		<div className="modal-wrapper">
			<div className="modal-header">
				<Title3 as="h1">{t('newBookingModal.newBooking')}</Title3>
				<Button
					style={{minWidth: '0', color: customTokens.colorBrandBackground, border: 'none'}}
					onClick={() => enableModal(false, undefined, undefined)}
				>
					X
				</Button>
			</div>
			{getModalContent()}
		</div>
	);
};
