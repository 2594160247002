import React from 'react';
import {createRoot} from 'react-dom/client';
import {mergeStyles} from '@fluentui/react';
import reportWebVitals from './reportWebVitals';
import './styles/index.scss';
import {FluentProvider} from '@fluentui/react-components';
import {customLightTheme} from './utils/createTheme';
import {initializeIcons} from '@fluentui/font-icons-mdl2';
import {App} from './pages/app/App';
import './i18n.js';
initializeIcons();

// Inject some global styles
mergeStyles({
	':global(body,html,#root)': {
		margin: 0,
		padding: 0,
		height: '100%',
	},
});

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
	<FluentProvider style={{height: '100%'}} theme={customLightTheme}>
		<App />
	</FluentProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
