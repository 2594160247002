import React, {createContext, useState} from 'react';
import {ContextProviderProps} from './contextInterface';

interface AlertContextType {
	alert: any;
	enableAlert: (newValue: any) => void;
}

export const AlertContext = createContext<AlertContextType>({alert: null, enableAlert: () => console.log('alert enabled')});

export const AlertContextProvider: React.FunctionComponent<ContextProviderProps> = (props: ContextProviderProps) => {
	const {children} = props;
	const [alert, setAlert] = useState(null);

	return <AlertContext.Provider value={{alert, enableAlert: (alert: any) => setAlert(alert)}}>{children}</AlertContext.Provider>;
};
