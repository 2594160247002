import React, {ChangeEvent} from 'react';
import {Label, Input, shorthands, tokens, Caption1} from '@fluentui/react-components';
import {makeResetStyles, makeStyles, mergeClasses} from '@griffel/react';
import {customTokens} from '../../utils/createTheme';

const useBaseClassName = makeResetStyles({
	'& span:after': {
		display: 'none',
	},
	'& span:focus-within': {
		border: `2px solid ${tokens.colorCompoundBrandBackground} !important`,
	},
	'& span': {
		borderRadius: '12px',
	},
});

const useErrorClassName = makeResetStyles({
	'& span:after': {
		display: 'none',
	},
	'& span': {
		border: `2px solid ${customTokens.colorRedHover} !important`,
		background: `${customTokens.colorRedLight}`,
		borderRadius: '12px',
	},
	'& span:focus-within': {
		border: `2px solid ${tokens.colorCompoundBrandBackground} !important`,
	},
	'& h5': {
		margin: 0,
		color: customTokens.colorRedHover,
	},
});

const useClassName = makeStyles({
	root: {
		// Stack the label above the field
		display: 'flex',
		flexDirection: 'column',
		// Use 2px gap below the label (per the design system)
		...shorthands.gap('2px'),
		// Prevent the example from taking the full width of the page (optional)
		width: '100%',
		maxWidth: '400px',
	},
});

interface CommonInputProps {
	inputId: string;
	ariaLabel: string; // label used for screen readers
	placeholder?: string | null;
	label?: string | null;
	disabled?: boolean | false;
	size?: 'medium' | 'small' | 'large' | undefined;
	error?: boolean;
	onChangeHandler: (e: React.ChangeEvent<HTMLInputElement>) => void;
	value?: string;
	noCustomClass?: boolean;
	maxLength?: number;
	type?: 'number' | 'text' | 'date';
}

export const CommonInput: React.FunctionComponent<CommonInputProps> = (props: CommonInputProps) => {
	const {inputId, disabled, size, label, ariaLabel, placeholder, error, onChangeHandler, value, noCustomClass, maxLength, type = 'text'} = props;
	const classes = useClassName();
	const baseClassName = useBaseClassName();
	const errorClassName = useErrorClassName();
	const className = mergeClasses(error ? errorClassName : baseClassName, classes.root);
	return (
		<div className={`${className} common-input`}>
			<Label htmlFor={inputId} size={props.size} disabled={props.disabled}>
				{label}
			</Label>
			<Input
				value={value}
				disabled={disabled}
				maxLength={maxLength}
				onChange={(e) => onChangeHandler(e)}
				size={size}
				aria-label={ariaLabel}
				type={type}
				id={inputId}
				placeholder={placeholder ?? ''}
				autoComplete={'on'}
				style={{
					padding: size === 'large' ? '14px 16px' : '',
				}}
			/>
			{error && <Caption1 as={'h5'}>{error}</Caption1>}
		</div>
	);
};
