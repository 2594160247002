import axios from 'axios';

axios.defaults.withCredentials = true;

const areaUrls = {
	area: '/biro/area',
	findMany: '/findMany',
	availabilityView: '/availabilityView',
};

/**
 * @param {string} office = office id for which to get areas
 * @returns The route request will get all the areas are in this office
 */
export function getAreas(office: string) {
	const spacestationUrl: string = process.env.REACT_APP_ENGINE_HOST_URL as string;
	const requestUrl = `${spacestationUrl + areaUrls.area + areaUrls.findMany}`;

	return axios({
		method: 'post',
		url: requestUrl,
		data: {
			office,
		},
	});
}

/**
 * @param {string} office = office id for which to get all areas and its available teak entities
 * @param {string} teakType = teak type for which to get all areas and its available teakEntities ( teakType = desk, room, parking space)
 * @param {string} start = start time of the requested range
 * @param {string} end = end time of the requested range
 * @returns The route request will get all areas and their available teak entities
 */
export function getAreasWithTheirAvailableEntities(office: string, teakType: string, start: string | null, end: string | null) {
	const spacestationUrl: string = process.env.REACT_APP_ENGINE_HOST_URL as string;
	const requestUrl = `${spacestationUrl + areaUrls.area + areaUrls.availabilityView}`;

	return axios({
		method: 'post',
		url: requestUrl,
		data: {
			office,
			teakType,
			range: {
				start,
				end,
			},
		},
	});
}
