import React, {createContext, useState} from 'react';
import {ContextProviderProps} from './contextInterface';
export enum ModalView {
	NewBooking,
	CancelBooking,
	BookingDetails,
	EditBooking,
}
interface ModalOptions {
	isModalOpen: boolean;
	modalView: ModalView | undefined;
	modalData: any;
}
interface ModalContextType {
	modalOptions: ModalOptions;
	enableModal: (isOpen: boolean, modalView: ModalView | undefined, modalData: any) => void;
}

export const ModalContext = createContext<ModalContextType>({
	modalOptions: {
		isModalOpen: false,
		modalView: undefined,
		modalData: null,
	},
	enableModal: () => console.log('modal enabled'),
});

export const ModalContextProvider: React.FunctionComponent<ContextProviderProps> = (props: ContextProviderProps) => {
	const {children} = props;
	const [modalOptions, setModalOptions] = React.useState<ModalOptions>({isModalOpen: false, modalView: undefined, modalData: null});

	return (
		<ModalContext.Provider
			value={{
				modalOptions,
				enableModal: (isModalOpen: boolean, modalView: ModalView | undefined, modalData: any) => {
					console.log(modalView);
					setModalOptions({
						isModalOpen,
						modalView,
						modalData,
					});
				},
			}}
		>
			{children}
		</ModalContext.Provider>
	);
};
