import React from 'react';
import {makeStyles, shorthands, Button, mergeClasses, tokens} from '@fluentui/react-components';
import {ChevronDoubleLeft20Filled} from '@fluentui/react-icons';
import {customTokens} from '../../utils/createTheme';

const usePrimaryButtonStyles = makeStyles({
	root: {
		...shorthands.borderRadius('32px'),
		':hover': {
			backgroundColor: '#312067',
			color: 'white',
		},
	},
});

const useOutlineButtonStyles = makeStyles({
	root: {
		...shorthands.borderRadius('32px'),
		...shorthands.borderColor(customTokens.colorBrandBackground),
		color: customTokens.colorBrandBackground,
		':hover': {
			backgroundColor: customTokens.colorBrandBackground,
			color: 'white',
		},
	},
});
const useDangerButtonStyles = makeStyles({
	root: {
		...shorthands.borderRadius('32px'),
		backgroundColor: customTokens.colorRed,
		color: 'white',
		':hover': {
			backgroundColor: customTokens.colorRedHover,
			color: 'white',
		},
	},
});
const useBackButtonStyles = makeStyles({
	root: {
		justifyContent: 'flex-start',
		...shorthands.margin('0'),
		...shorthands.padding('0'),
	},
});

interface CommonButtonProps {
	appearance: 'outline' | 'transparent' | 'secondary' | 'primary' | 'subtle' | 'back' | 'danger' | undefined;
	buttonId: string;
	text?: string | undefined | null;
	disabled?: boolean | false;
	size?: 'medium' | 'small' | 'large' | undefined;
	onClickHandler: (e: React.MouseEvent) => void;
	loading?: boolean;
	icon?: any;
	className?: string;
}

export const CommonButton: React.FunctionComponent<CommonButtonProps> = (props: CommonButtonProps) => {
	const {buttonId, disabled, size, text, onClickHandler, loading, appearance, icon, className} = props;
	const backButtonClassName = useBackButtonStyles();
	const primaryButtonClassName = usePrimaryButtonStyles();
	const outlineButtonClassName = useOutlineButtonStyles();
	const dangerButtonClassName = useDangerButtonStyles();

	function getStyleForAppeareance() {
		switch (appearance) {
			case 'outline':
				return outlineButtonClassName;
			default:
				return primaryButtonClassName;
		}
	}
	const buttonClassName = className ? mergeClasses(getStyleForAppeareance().root, className) : getStyleForAppeareance().root;
	if (appearance === 'back') {
		return (
			<Button
				className={backButtonClassName.root}
				icon={icon ? icon : <ChevronDoubleLeft20Filled />}
				appearance="transparent"
				onClick={onClickHandler}
			>
				{text}
			</Button>
		);
	}
	if (appearance === 'danger') {
		return (
			<Button className={dangerButtonClassName.root} appearance="outline" onClick={onClickHandler}>
				{text}
			</Button>
		);
	}

	return (
		<Button
			disabled={disabled || loading}
			className={buttonClassName}
			onClick={(e) => onClickHandler(e)}
			appearance={appearance}
			id={buttonId}
			size={size}
		>
			{text}
		</Button>
	);
};
