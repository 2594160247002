import React from 'react';
import {Combobox, ComboboxProps, makeStyles, shorthands} from '@fluentui/react-components';
import {customTokens} from '../../utils/createTheme';

type CommonComboboxProps = ComboboxProps & {
	// Define additional props for the custom button component here
	icon?: string;
};

const useStyles = makeStyles({
	root: {
		...shorthands.borderRadius(customTokens.borderRadiusXXLarge),
		...shorthands.borderColor(customTokens.colorNeutralStroke1),
		minWidth: 'none',
		'&:hover': {
			...shorthands.borderColor(customTokens.colorNeutralStroke1Hover),
		},

		'&:active': {
			...shorthands.borderColor(customTokens.colorNeutralStroke1Pressed),
		},
	},
});

export const CommonCombobox: React.FunctionComponent<CommonComboboxProps> = (props) => {
	const {children, ...rest} = props;
	const styles = useStyles();

	return (
		<React.StrictMode>
			<Combobox className={styles.root} {...rest}>
				{children}
			</Combobox>
		</React.StrictMode>
	);
};
