/**
 * This function rounds a given time to the nearest quarter hour and returns the rounded time in ISO string format.
 *@param {Date} time - The time to round to the nearest quarter hour
 *@returns {Date} - The rounded time in ISO string format
 */
export function roundTimeQuarterHour(time: string): string {
	if (!time) return new Date().toISOString();
	const timeToReturn = new Date(time);

	timeToReturn.setMilliseconds(Math.ceil(timeToReturn.getMilliseconds() / 1000) * 1000);
	timeToReturn.setSeconds(Math.ceil(timeToReturn.getSeconds() / 60) * 60);
	timeToReturn.setMinutes(Math.ceil(timeToReturn.getMinutes() / 15) * 15);
	if (!timeToReturn.toISOString()) {
		return new Date().toISOString();
	}
	return timeToReturn.toISOString();
}
