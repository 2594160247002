import React, {useContext, useEffect} from 'react';
import {createBrowserRouter, RouterProvider, useNavigate} from 'react-router-dom';

import {getMember} from '../../api/member';
import {ROUTES} from '../../constants/routes';
import {Header} from '../../components/Header/Header';
import {Footer} from '../../components/Footer';
import {Modal} from '../modal/Modal';
import {OTP} from '../otp/OTP';
import {Login} from '../login/Login';
import {Router} from '../router/Router';
import {getManyOffices} from '../../api/office';
import {getDataForOffice} from '../../utils/getDataForOffice';
import {CommonAlert} from '../../components/messages/CommonAlert';
import {DashboardContext, DashboardContextProvider} from './contexts/DashboardContext';
import {CurrentUserContext, CurrentUserContextProvider} from './contexts/CurrentUserContext';
import {AlertContextProvider} from './contexts/AlertContext';
import {ModalContextProvider} from './contexts/ModalContext';
import {InitialOfficeSelection} from '../initialOfficeSelection/InitialOfficeSelection';
import {ProfileCompletion} from '../profileCompletion/ProfileCompletion';
import {Spinner} from '@fluentui/react-components';

export const App: React.FunctionComponent = () => {
	const router = createBrowserRouter([
		{
			path: ROUTES.DEFAULT,
			element: <PageLayout />,
		},
		{
			path: ROUTES.LOGIN,
			element: <Login />,
		},
		{
			path: ROUTES.OTP,
			element: <OTP />,
		},
		{
			path: ROUTES.INITIAL_OFFICE_SELECTION,
			element: <InitialOfficeSelection />,
		},
		{
			path: ROUTES.PROFILE_COMPLETION,
			element: <ProfileCompletion />,
		},
	]);
	return (
		<ModalContextProvider>
			<AlertContextProvider>
				<CurrentUserContextProvider>
					<DashboardContextProvider>
						<Modal />
						<CommonAlert />
						<RouterProvider router={router} />
					</DashboardContextProvider>
				</CurrentUserContextProvider>
			</AlertContextProvider>
		</ModalContextProvider>
	);
};

const PageLayout: React.FunctionComponent = () => {
	const {updateUser} = useContext(CurrentUserContext);
	const {updateLoadingDashboard, updateOffices, updateSelectedOffice} = useContext(DashboardContext);
	const navigate = useNavigate();
	const [loading, setLoading] = React.useState(false);
	function redirectToLogin() {
		navigate(ROUTES.LOGIN);
	}
	function redirectToOfficeSelection() {
		navigate(ROUTES.INITIAL_OFFICE_SELECTION);
	}

	async function updateOfficeData(officeId: string) {
		try {
			const officesResponse = await getManyOffices();
			updateOffices(officesResponse.data);
			if (officeId) {
				const selectedOfficeOfUser = officesResponse.data.find((office: any) => office?._id === officeId);
				const selectedOfficeCompleteData = await getDataForOffice(selectedOfficeOfUser?._id);
				updateSelectedOffice({...selectedOfficeCompleteData, ...selectedOfficeOfUser, id: officeId});
				updateLoadingDashboard(false);
				setLoading(false);
			}
		} catch (error) {
			updateLoadingDashboard(false);
			setLoading(false);
			console.log(error);
		}
	}

	async function checkIfMemberIsLoggedIn() {
		setLoading(true);
		const memberId = window.localStorage.getItem('memberId');
		if (!memberId) {
			redirectToLogin();
			return;
		}
		updateLoadingDashboard(true);
		try {
			const memberResponse = await getMember(memberId);
			const userData = memberResponse?.data;
			updateUser(userData);
			if (!userData?.biro?.lastOfficeSelection) {
				redirectToOfficeSelection();
				setLoading(false);
				return;
			}
			updateOfficeData(userData.biro?.lastOfficeSelection);
		} catch (error) {
			updateLoadingDashboard(false);
			redirectToLogin();
			setLoading(false);
			console.log(error);
		}
	}
	useEffect(() => {
		checkIfMemberIsLoggedIn();
	}, []);

	if (loading) {
		return (
			<div className="loading-page">
				<Spinner size="huge" />
			</div>
		);
	}
	return (
		<div className="App">
			<Header />
			<Router />
			<Footer />
		</div>
	);
};
