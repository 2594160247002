import React, {useContext} from 'react';
import {ModalContext, ModalView} from '../app/contexts/ModalContext';
import {BookingDetailsModal} from './BookingDetailsModal/BookingDetails';
import {BookingModal} from './BookingModal/BookingModal';
import {CancelBookingModal} from './CancelBookingModal/CancelBookingModal';
import {EditBookingModal} from './EditBookingModal/EditBookingModal';
import {Dialog, DialogContent, DialogSurface} from '@fluentui/react-components';

export const Modal: React.FunctionComponent = () => {
	const {modalOptions} = useContext(ModalContext);
	function getModalView() {
		switch (modalOptions.modalView) {
			case ModalView.NewBooking:
				return <BookingModal />;
			case ModalView.CancelBooking:
				return <CancelBookingModal />;
			case ModalView.BookingDetails:
				return <BookingDetailsModal />;
			case ModalView.EditBooking:
				return <EditBookingModal />;
			default:
				break;
		}
	}
	return (
		<React.StrictMode>
			<Dialog open={modalOptions.isModalOpen}>
				<DialogSurface style={{minWidth: '70%'}}>
					<DialogContent>{getModalView()}</DialogContent>
				</DialogSurface>
			</Dialog>
		</React.StrictMode>
	);
};
