import React, {useEffect} from 'react';
import {DatePicker, DayOfWeek, defaultDatePickerStrings, TimePicker, mergeStyles} from '@fluentui/react';
import {Label, tokens} from '@fluentui/react-components';
import {makeResetStyles} from '@griffel/react';
import {DateTime} from 'luxon';
import {customTokens} from '../../utils/createTheme';
import {useTranslation} from 'react-i18next';

//change timezone of default timepicker value to start the range expected for the timezone.
function changeTimezone(date: Date, ianatz: string) {
	// suppose the date is 12:00 UTC
	const invdate = new Date(
		date.toLocaleString('en-US', {
			timeZone: ianatz,
		}),
	);

	// then invdate will be 07:00 in Toronto
	// and the diff is 5 hours
	const diff = date.getTime() - invdate.getTime();

	// so 12:00 in Toronto is 17:00 UTC
	return new Date(date.getTime() - diff); // needs to substract
}

const useTimePickerClassName = makeResetStyles({
	'& div::after': {
		borderRadius: '12px',
	},
	'& button': {
		color: tokens.colorBrandBackground,
		borderTopRightRadius: '12px',
		borderBottomRightRadius: '12px',
		'& i': {
			fontWeight: 'bold',
		},
	},
});

const useTimePickerErrorClassName = makeResetStyles({
	'& div': {
		borderRadius: '12px',
		borderColor: customTokens.colorRedHover,
		backgroundColor: customTokens.colorRedLight,
	},
	'& input': {
		borderColor: customTokens.colorRedHover,
		backgroundColor: customTokens.colorRedLight,
	},
	'& div::after': {
		borderRadius: '12px',
		borderColor: customTokens.colorRedHover,
	},
	'& button': {
		color: tokens.colorBrandBackground,
		borderTopRightRadius: '12px',
		borderBottomRightRadius: '12px',
		zIndex: '1',
		'& i': {
			fontWeight: 'bold',
		},
	},
});
const useDatePickerClassName = makeResetStyles({
	'& div::after': {
		borderRadius: '12px',
	},
	'& div': {
		borderRadius: '12px',
	},
});

const useDatePickerErrorClassName = makeResetStyles({
	'& div::after': {
		borderRadius: '12px',
		borderColor: customTokens.colorRedHover,
	},
	'& div': {
		borderRadius: '12px',
		borderColor: customTokens.colorRedHover,
		backgroundColor: customTokens.colorRedLight,
	},
});
const iconStyles = mergeStyles({
	color: '#5236ab',
});
interface DateTimePickerProps {
	dateLabel: string;
	timeLabel: string;
	dateTime: DateTime;
	timezone?: string;
	onChange: (newValue: DateTime) => void;
	timeError?: boolean;
	dateError?: boolean;
}

export const DateTimePicker: React.FunctionComponent<DateTimePickerProps> = (props: DateTimePickerProps) => {
	const {dateLabel = 'Date', timeLabel = 'Time', onChange, dateTime, timezone, timeError, dateError} = props;
	const dateTimeAsDate = dateTime.toJSDate();
	const datePickerClassName = useDatePickerClassName();
	const datePickerErrorClassName = useDatePickerErrorClassName();
	const timePickerClassName = useTimePickerClassName();
	const timePickerErrorClassName = useTimePickerErrorClassName();
	const {t} = useTranslation();

	function dateChangeHandler(newDate: Date | null | undefined) {
		if (newDate) {
			const newDateTime = DateTime.fromObject(
				{
					year: newDate?.getFullYear(),
					month: Math.abs(newDate?.getMonth() + 1),
					day: newDate?.getDate(),
					hour: dateTime.hour,
					minute: dateTime.minute,
				},
				{zone: timezone},
			);
			onChange(newDateTime);
		}
	}

	function timeChangeHandler(newTime: Date) {
		const newDateTime = DateTime.fromObject(
			{
				year: dateTime.year,
				month: dateTime.month,
				day: dateTime.day,
				hour: newTime.getHours(),
				minute: newTime.getMinutes(),
			},
			{zone: timezone},
		);
		onChange(newDateTime);
	}

	function formatDate(date?: Date): string {
		if (!date) return '';
		const month = date.getMonth() + 1; // + 1 because 0 indicates the first Month of the Year.
		const day = date.getDate();
		const year = date.getFullYear();
		return `${day}/${month}/${year}`;
	}
	return (
		<React.Fragment>
			<div className="date-wrapper">
				<Label as="label" id={'date-time-label'}>
					{dateLabel}
				</Label>
				<DatePicker
					formatDate={formatDate}
					value={dateTimeAsDate}
					className={dateError ? datePickerErrorClassName : datePickerClassName}
					firstDayOfWeek={DayOfWeek.Monday}
					placeholder="Select a date..."
					ariaLabel={t('common.ariaLabelDatepicker') ?? 'Select a date'}
					styles={{icon: iconStyles}}
					onSelectDate={(date) => dateChangeHandler(date)}
					// DatePicker uses English strings by default. For localized apps, you must override this prop.
					strings={defaultDatePickerStrings}
					aria-describedby="date picker select start date"
					allowTextInput={true}
				/>
			</div>
			<div className="time-wrapper">
				<Label as="label" id={'date-time-label'}>
					{timeLabel}
				</Label>
				<TimePicker
					useHour12
					allowFreeform={false}
					increments={15}
					className={timeError ? timePickerErrorClassName : timePickerClassName}
					autoComplete="on"
					onChange={(e, time) => timeChangeHandler(time)}
					defaultValue={timezone ? changeTimezone(dateTimeAsDate, timezone) : dateTimeAsDate}
					useComboBoxAsMenuWidth
					ariaLabel={t('common.ariaLabelTimepicker') ?? 'Select a time'}
				/>
			</div>
		</React.Fragment>
	);
};
