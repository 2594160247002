import React, {useContext} from 'react';
import {useTranslation} from 'react-i18next';
import {
	AvatarGroup,
	AvatarGroupItem,
	AvatarGroupPopover,
	Button,
	makeStyles,
	Menu,
	MenuItem,
	MenuList,
	MenuPopover,
	MenuTrigger,
	mergeClasses,
	partitionAvatarGroupItems,
	shorthands,
	Spinner,
	Text,
} from '@fluentui/react-components';
import {DateTime} from 'luxon';

import {Card, CardFooter, CardHeader} from '@fluentui/react-components';
import {Checkmark16Regular, MoreHorizontal20Filled} from '@fluentui/react-icons';
import {customTokens} from '../../utils/createTheme';
import {FormattedDateTime} from '../dateTime/FormattedDateTime';
import {TEAK_BOOKING_STATUS} from '../../constants/teakBookingStatus';
import {checkInNow, checkOutNow} from '../../api/teakBooking';
import {CommonButton} from '../button/CommonButton';
import {DashboardContext} from '../../pages/app/contexts/DashboardContext';
import {ModalContext, ModalView} from '../../pages/app/contexts/ModalContext';
import {AlertContext} from '../../pages/app/contexts/AlertContext';
import {CurrentUserContext} from '../../pages/app/contexts/CurrentUserContext';

const useStyles = makeStyles({
	basic: {
		...shorthands.borderRadius(customTokens.borderRadiusXXLarge),
		...shorthands.padding('0'),
	},
	active: {
		...shorthands.borderRadius(customTokens.borderRadiusXXLarge),
		...shorthands.padding('0'),
		...shorthands.borderColor(customTokens.colorGreen),
		...shorthands.borderStyle('solid'),
	},
	green: {
		color: customTokens.colorGreen,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		...shorthands.gap('5px'),
	},
});

interface BookingCardProps {
	booking: any;
	isPast?: boolean;
}

export const BookingCard: React.FunctionComponent<BookingCardProps> = (props: BookingCardProps) => {
	const {t} = useTranslation();
	const {booking, isPast} = props;
	const {selectedOffice, loadBookings} = useContext(DashboardContext);
	const {enableModal} = useContext(ModalContext);
	const {enableAlert} = useContext(AlertContext);
	const {currentUser} = React.useContext(CurrentUserContext);
	// 0 -> checkin button is displayed
	// 1 -> loading spinner is displayed
	// 2 => checkedin status is displayed
	const [checked, setChecked] = React.useState(booking?.status === TEAK_BOOKING_STATUS.checkedIn ? 2 : 0);
	// const meeting_room_icon = `${process.env.PUBLIC_URL}/images/meeting_room_icon.svg`;
	const djLogo = `${process.env.PUBLIC_URL}/images/logo_icon.svg`;
	const filteredBookingAttendees = booking?.attendees?.filter((attendee: any) => attendee?.member?._id !== currentUser._id);
	const attendeeNames: Array<string> = filteredBookingAttendees.map(
		(attendee: any) =>
			`${attendee?.member?.name?.full ? attendee.member.name.full : attendee.member?.name?.first + ' ' + attendee.member?.name?.last}`,
	);
	const partitionedItems = partitionAvatarGroupItems({items: attendeeNames});
	const styles = useStyles();
	const cardClassNames = mergeClasses(checked === 2 ? styles.active : styles.basic, 'booking-card-wrapper');
	const isMemberHost = booking?.member?.id === currentUser?._id;

	const onCheckin = async () => {
		setChecked(1);
		try {
			await checkInNow(booking?._id);
			enableAlert({show: true, intent: 'success', text: t('success.checkinSuccessful')});
			setChecked(2);
		} catch (error: any) {
			console.log(error);
			enableAlert({
				show: true,
				intent: 'error',
				text: error?.response?.data?.name ? `errors.checkInErrors.${error.response.data.name}` : 'genericError',
			});
			setChecked(0);
		}
	};

	const onCheckout = async () => {
		setChecked(1);
		try {
			await checkOutNow(booking?._id);
			enableAlert({show: true, intent: 'success', text: t('success.checkedOut')});
			setChecked(0);
			loadBookings();
		} catch (error: any) {
			enableAlert({
				show: true,
				intent: 'error',
				text: error?.response?.data?.name ? `errors.checkOutErrors.${error.response.data.name}` : 'genericError',
			});
			setChecked(2);
		}
	};

	function isBookingConfirmedAndOwnedByUser() {
		return (
			(booking?.status === TEAK_BOOKING_STATUS.checkInOpen || booking?.status === TEAK_BOOKING_STATUS.confirmed) &&
			isMemberHost &&
			checked !== 2
		);
	}

	function getAreaName() {
		const bookingOfArea = selectedOffice?.areas?.find((area: any) => area?._id === booking?.teakEntity?.area);
		return bookingOfArea ? bookingOfArea?.name : '';
	}

	function openDeleteBookingModal() {
		enableModal(true, ModalView.CancelBooking, booking);
	}

	function openEditBookingModal() {
		enableModal(true, ModalView.EditBooking, booking);
	}

	function openBookingDetailsModal() {
		enableModal(true, ModalView.BookingDetails, booking);
	}

	//added margin-left of 36 px since the content should be under the name and not under the icon. 36px because 24px is the icon width + 12px gap
	return (
		<Card className={cardClassNames}>
			<CardHeader
				style={{padding: '16px'}}
				image={<img style={{width: '24px', height: '24px'}} src={djLogo} alt="Meeting Room" />}
				header={<Text weight="semibold">{booking?.teakEntity?.name}</Text>}
				// action={
				// 	<Button
				// 		appearance="transparent"
				// 		icon={<Heart20Regular />}
				// 		aria-label={t('bookingCard.ariaLabelFavourite') ?? 'Mark asset as favorite'}
				// 	/>
				// }
			/>
			<Text style={{marginLeft: '36px', padding: '0 16px'}} as="span">
				<b>{t('common.from')}</b>: <FormattedDateTime dateTime={DateTime.fromISO(booking?.start?.time)} />
			</Text>
			<Text style={{marginLeft: '36px', padding: '0 16px'}} as="span">
				<b>{t('common.to')}</b>: <FormattedDateTime dateTime={DateTime.fromISO(booking?.end?.time)} />
			</Text>
			<Text style={{marginLeft: '36px', padding: '0 16px'}} as="span">
				{getAreaName()}
			</Text>
			{partitionedItems?.inlineItems.length > 0 && (
				<AvatarGroup style={{marginLeft: '36px', padding: '0 16px'}} layout="stack" size={24}>
					{partitionedItems.inlineItems.map((name) => (
						<AvatarGroupItem name={name} key={name} />
					))}

					{partitionedItems.overflowItems && (
						<AvatarGroupPopover count={2} indicator="count">
							{partitionedItems.overflowItems.map((name) => (
								<AvatarGroupItem name={name} key={name} />
							))}
						</AvatarGroupPopover>
					)}
				</AvatarGroup>
			)}
			<div aria-label={t('bookingCard.ariaLabelStatus') ?? 'Status of the booking'} className={'booking-card-status-wrapper'}>
				<div className={'booking-card-status status-' + booking?.status}>
					<p>{t(`bookingStatus.${booking?.status}`)}</p>
				</div>
			</div>
			<CardFooter
				style={{
					padding: '16px',
					backgroundColor: '#F5F5F5',
					borderBottomLeftRadius: customTokens.borderRadiusXXLarge,
					borderBottomRightRadius: customTokens.borderRadiusXXLarge,
				}}
				action={
					isPast ? null : checked === 0 ? (
						<CommonButton
							appearance="outline"
							onClickHandler={onCheckin}
							buttonId="checkin-button-booking-card"
							text={t('common.checkIn')}
						/>
					) : checked === 1 ? (
						<Spinner size="extra-small" label={t('bookingCard.checkingIn')} />
					) : (
						<div className={styles.green}>
							<Checkmark16Regular />
							<Text color={customTokens.colorGreen}>{t('bookingCard.checkedIn')}</Text>
						</div>
					)
				}
			>
				<Menu>
					<MenuTrigger disableButtonEnhancement>
						<Button appearance="transparent" icon={<MoreHorizontal20Filled />} aria-label="More options" />
					</MenuTrigger>
					<MenuPopover>
						<MenuList>
							<MenuItem onClick={openBookingDetailsModal}>{t('common.bookingDetails')}</MenuItem>
							{isBookingConfirmedAndOwnedByUser() && <MenuItem onClick={openDeleteBookingModal}>{t('common.cancelBooking')}</MenuItem>}
							{checked === 2 && <MenuItem onClick={onCheckout}>{t('common.checkOut')}</MenuItem>}
							{isBookingConfirmedAndOwnedByUser() && <MenuItem onClick={openEditBookingModal}>{t('common.editBooking')}</MenuItem>}
						</MenuList>
					</MenuPopover>
				</Menu>
			</CardFooter>
		</Card>
	);
};
