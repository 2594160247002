import React from 'react';
import {Body1, Link, makeStyles} from '@fluentui/react-components';
import {useTranslation} from 'react-i18next';

import '../styles/footer.scss';
import {customTokens} from '../utils/createTheme';

const useLinkStyles = makeStyles({
	root: {
		color: customTokens.colorGrey97,
		fontWeight: 'bold',
	},
});
export const Footer: React.FunctionComponent = () => {
	const linkClassName = useLinkStyles();
	const {t} = useTranslation();
	return (
		<footer className="footer">
			<div className="footer-copyright">
				<Body1>{t('login.solutionBy')}</Body1>
				<img className={'cgi-logo'} alt="CGI" src={process.env.PUBLIC_URL + '/images/cgi_logo.svg'} />
				<Body1>{t('login.copyright')}</Body1>
			</div>
			{/* <ul className="footer-links">
				<li className="footer-links-item">
					<Link className={linkClassName.root} target="_blank" href="https://www.bing.com">
						{t('footer.help')}
					</Link>
				</li>
				<li className="footer-links-item">
					<Link className={linkClassName.root} target="_blank" href="https://www.bing.com">
						{t('footer.imprint')}
					</Link>
				</li>
				<li className="footer-links-item">
					<Link className={linkClassName.root} target="_blank" href="https://www.bing.com">
						{t('footer.privacy')}
					</Link>
				</li>
				<li className="footer-links-item">
					<Link className={linkClassName.root} target="_blank" href="https://www.bing.com">
						{t('footer.accessibility')}
					</Link>
				</li>
			</ul> */}
		</footer>
	);
};
