import {makeResetStyles, Subtitle1, Title1, tokens} from '@fluentui/react-components';
import {Dropdown, IDropdownOption} from '@fluentui/react';
import {useTranslation} from 'react-i18next';
import React, {useContext, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {CommonButton} from '../../components/button/CommonButton';
import {getOfficesGrouped} from '../../api/office';
import {CurrentUserContext} from '../app/contexts/CurrentUserContext';
import {updateUserOffice} from '../../api/member';
import {ROUTES} from '../../constants/routes';
import {AlertContext} from '../app/contexts/AlertContext';

const backgroundImage = `${process.env.PUBLIC_URL}/images/login_background.svg`;
const doorjamesLogoUrl = `${process.env.PUBLIC_URL}/images/DOORJAMES_LOGO.svg`;

const useDropdownStyles = makeResetStyles({
	width: '100%',
	'& div': {
		width: '100%', //same max width as the max widht of the modal content
	},
	'& div::after': {
		borderRadius: '12px',
	},
	'& span': {
		borderRadius: '12px',
		textOverflow: 'ellipsis',
		'& i': {
			color: tokens.colorBrandBackground,
			fontWeight: 'bold',
		},
	},
});

export const InitialOfficeSelection: React.FunctionComponent = () => {
	const {t} = useTranslation();
	const {currentUser} = useContext(CurrentUserContext);
	const {enableAlert} = useContext(AlertContext);
	const [officeSelection, setOfficeSelection] = React.useState<string | number | undefined>();
	const [countrySelection, setCountrySelection] = React.useState<string | number | undefined>();
	const dropdownStyles = useDropdownStyles();
	const navigate = useNavigate();
	const [officesOptions, setOfficesOptions] = React.useState();
	const [officesGroupedData, setOfficesGroupedData] = React.useState<any>();
	const [loading, setLoading] = React.useState(false);

	useEffect(() => {
		if (!currentUser) {
			navigate(ROUTES.DEFAULT);
			return;
		}
		loadOfficesGrouped();
	}, []);

	async function loadOfficesGrouped() {
		try {
			const officesGroupedResponse = await getOfficesGrouped();
			setOfficesGroupedData(officesGroupedResponse.data);
		} catch (error) {
			enableAlert({
				show: true,
				intent: 'error',
				text: 'errors.loginErrors.failedOfficeGrouped',
			});
		}
	}
	async function confirmSelectionHandler() {
		if (!currentUser._id) {
			navigate(ROUTES.DEFAULT);
			return;
		}
		if (!officeSelection) return;
		setLoading(true);
		try {
			await updateUserOffice(currentUser._id, officeSelection);
			navigate(ROUTES.DEFAULT);
		} catch (error) {
			console.log(error);
			enableAlert({
				show: true,
				intent: 'error',
				text: 'errors.loginErrors.failedOfficeSelection',
			});
			setLoading(false);
		}
	}

	function countrySelectionHandler(countrySelected: string | undefined | number) {
		if (!countrySelected) return;
		setCountrySelection(countrySelected);
		setOfficesOptions(officesGroupedData[countrySelected]);
	}

	function getOptions(optionsArray: string[] | undefined) {
		if (!optionsArray) return [];
		const options: IDropdownOption[] = [];
		optionsArray.forEach((option: any) => {
			options.push({key: option._id ? option._id : option, text: option.name ? option.name : option});
		});
		return options;
	}

	return (
		<React.StrictMode>
			<div className="initial-office-selection-page intro-page">
				<div className="initial-office-selection-page-content intro-page-content">
					<div className="initial-office-selection-page-content-details intro-page-content-details">
						<img className="doorjames-logo" src={doorjamesLogoUrl} alt="Door James" />
						<Title1 as="h1" className="header">
							{' '}
							{t('initialOfficeSelection.officeSelection')}{' '}
						</Title1>
						<Subtitle1 as="h2" className="header">
							{' '}
							{t('initialOfficeSelection.officeSelectionInfo')}{' '}
						</Subtitle1>
						<Subtitle1 as="h2" id={'office-label'}>
							{t('common.country')}
						</Subtitle1>
						<Dropdown
							className={dropdownStyles}
							aria-label={
								t('initialOfficeSelection.ariaLabelCountrySelection') ?? 'Dropdown for selecting the country of your initial office'
							}
							placeholder={t('initialOfficeSelection.countryPlaceholder') ?? 'Select country'}
							selectedKey={countrySelection}
							options={officesGroupedData ? getOptions(Object.keys(officesGroupedData)) : []}
							onChange={(e, option) => countrySelectionHandler(option?.key)}
						/>

						<Subtitle1 as="h2" id={'office-label'}>
							{t('common.office')}
						</Subtitle1>
						<Dropdown
							className={dropdownStyles}
							aria-label={t('initialOfficeSelection.ariaLabelOfficeSelection') ?? 'Dropdown for selecting your initial office'}
							placeholder={t('initialOfficeSelection.officePlaceholder') ?? 'Select office'}
							selectedKey={officeSelection}
							options={getOptions(officesOptions)}
							onChange={(e, option) => setOfficeSelection(option?.key)}
						/>
					</div>

					<CommonButton
						buttonId="search-results-form"
						appearance="primary"
						disabled={!countrySelection || !officeSelection}
						onClickHandler={confirmSelectionHandler}
						loading={loading}
						text={t('common.confirm')}
					/>
				</div>
				<div className="initial-office-selection-page-image-wrapper intro-page-image-wrapper">
					<img src={backgroundImage} alt="" />
				</div>
			</div>
		</React.StrictMode>
	);
};
